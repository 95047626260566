import React from "react";
import { useAppDescriptorStore } from "../../../stores/appDescriptorStore";
import { ComponentInstance } from "./ViewgraphEditor";
import DOMPurify from "dompurify";

interface ViewgraphComponentInstanceProps {
  componentInstance: ComponentInstance;
  isSelected: boolean;
}

const ViewgraphComponentInstance: React.FC<ViewgraphComponentInstanceProps> = ({
  componentInstance,
  isSelected,
}) => {
  const { availableComponentBlueprints } = useAppDescriptorStore();

  const blueprint = availableComponentBlueprints.find(
    (bp) => bp.name === componentInstance.blueprintName
  );

  if (!blueprint) {
    return (
      <div className="p-4 bg-gray-100 rounded">
        Component blueprint not found: {componentInstance.blueprintName}
      </div>
    );
  }

  const generatePreviewHtml = () => {
    if (!blueprint.previewHtml) {
      return `<div class="p-4 bg-gray-100 rounded">
        Preview not available for ${componentInstance.blueprintName}
      </div>`;
    }

    try {
      let previewHtml = blueprint.previewHtml;

      if (componentInstance.propertiesBindings) {
        Object.entries(componentInstance.propertiesBindings).forEach(
          ([key, binding]) => {
            const value = binding.config?.value ?? binding;
            const regex = new RegExp(`\\$\\{${key}\\}`, "g");
            previewHtml = previewHtml.replace(regex, value);
          }
        );
      }

      return DOMPurify.sanitize(previewHtml, {
        FORBID_ATTR: ["onload", "onerror", "onclick", "onmouseover"],
        FORBID_TAGS: ["script", "style"],
        ADD_ATTR: ["data-sanitized"],
      });
    } catch (error) {
      console.error("Error generating preview HTML:", error);
      return `<div class="p-4 bg-red-100 rounded">
        Error generating preview for ${componentInstance.blueprintName}
      </div>`;
    }
  };

  return (
    <div
      className={`relative p-2 rounded ${
        isSelected ? "ring-2 ring-blue-500" : ""
      }`}
    >
      <div
        className="preview-content"
        dangerouslySetInnerHTML={{ __html: generatePreviewHtml() }}
      />
    </div>
  );
};

export default ViewgraphComponentInstance;
