import { v4 as uuidv4 } from 'uuid';
import pluralize from 'pluralize';
import { DataModel } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

export class DefaultPageGroupGenerator {
  private pageGroupId: string;
  private indexPageId: string;
  private showPageId: string;
  private newPageId: string;
  private editPageId: string;
  private createPageId: string;
  private updatePageId: string;
  private destroyPageId: string;
  private dataModel: DataModel;


  constructor(dataModel: DataModel) {
    this.dataModel = dataModel;

    this.pageGroupId = uuidv4();
    this.indexPageId = uuidv4();
    this.showPageId = uuidv4();
    this.newPageId = uuidv4();
    this.editPageId = uuidv4();
    this.createPageId = uuidv4();
    this.updatePageId = uuidv4();
    this.destroyPageId = uuidv4();
  }

  private get dataModelName() {
    return this.dataModel.name;
  }

  private get dataModelNamePlural() {
    return pluralize(this.dataModel.name);
  }

  private get dataModelDefaultFields() {
    return this.dataModel.fields?.slice(0, 2) ?? [];
  }

  private generateDefaultContainerStructure(pageId: string): ContainerStructure {
    return {
      id: "root",
      isRoot: true,
      layoutDirection: 'vertical',
      size: { value: 1, unit: 'fr' },
      components: [],
      subcontainers: [
        {
          id: "UI",
          layoutDirection: 'vertical',
          size: { value: 1, unit: 'fr' },
          components: [],
          subcontainers: [],
          isScrollable: true,
          isLayoutApplied: true,
        }
      ],
      isScrollable: true,
      isLayoutApplied: true,
    };
  }

  generatePageGroup(dataModelId: string, dataModelName: string) {
    const pages = [
      this.generateIndexPage(),
      this.generateShowPage(),
      this.generateNewPage(),
      this.generateEditPage(),
      this.generateCreatePage(),
      this.generateUpdatePage(),
      this.generateDestroyPage()
    ];

    return {
      id: this.pageGroupId,
      name: `${dataModelName} Pages`,
      pages,
      basePath: `/${dataModelName.toLowerCase()}s`,
      dataModelId
    };
  }

  generateIndexPage() {
    return {
      id: this.indexPageId,
      name: `${this.dataModelName} Index`,
      description: `Render a page to list all ${this.dataModelName.toLowerCase()} records.`,
      pageType: "index",
      dataModelId: this.dataModel.id,
      relativePath: "/",
      userInterface: this.generateIndexPageUI(),
      initializationLogic: this.generateIndexPageLogic()
    };
  }

  generateIndexPageUI() {
    const containerStructure = this.generateDefaultContainerStructure(this.indexPageId);
    containerStructure.subcontainers[0].components = [
      {
        id: uuidv4(),
        layout: { width: "w-full" },
        reactions: {},
        blueprintName: "CollectionTable",
        propertiesBindings: {
          tableConfig: {
            config: {
              type: "_types.Dictionary",
              value: {
                columns: {
                  config: {
                    type: "_types.CollectionTableColumnsConfig",
                    value: this.dataModelDefaultFields.map(field => (
                      { fieldKey: field.name, title: field.name }
                    )),
                  },
                  directiveType: "literalValue"
                },
                records: {
                  config: { variableName: "records" },
                  directiveType: "getPageVariable"
                },
                dataModelId: {
                  config: { type: "_types.String", value: this.dataModel.id },
                  directiveType: "literalValue"
                }
              }
            },
            directiveType: "literalValue"
          },
          title: {
            config: { type: "_types.String", value: this.dataModelNamePlural },
            directiveType: "literalValue"
          },
          showNewRecordButton: {
            config: { type: "_types.Boolean", value: true },
            directiveType: "literalValue"
          },
          newRecordButtonText: {
            config: { type: "_types.String", value: "New" },
            directiveType: "literalValue"
          },
        }
      }
    ];

    return {
      viewgraph: {
        enabled: true,
        containerStructure
      }
    };
  }

  generateIndexPageLogic() {
    return {
      flowgraph: [
        {
          directiveType: "declarePageVariable",
          config: {
            name: "records",
            type: "_types.ActiveCollection",
            initialValue: {
              id: uuidv4(),
              config: {
                method: "all",
                arguments: {},
                modelName: this.dataModelName
              },
              directiveType: "executeModelMethod"
            }
          }
        }
      ],
      parameters: []
    };
  }

  generateShowPage() {
    return {
      id: this.showPageId,
      name: `${this.dataModelName} Show`,
      description: `Render a page to display the data for a single ${this.dataModelName.toLowerCase()} record.`,
      pageType: "show",
      dataModelId: this.dataModel.id,
      relativePath: "/:id",
      userInterface: this.generateShowPageUI(),
      initializationLogic: this.generateShowPageLogic()
    };
  }

  generateShowPageUI() {
    const containerStructure = this.generateDefaultContainerStructure(this.showPageId);
    containerStructure.subcontainers[0].components = [
      {
        id: uuidv4(),
        reactions: {},
        blueprintName: "ShowRecordCard",
        propertiesBindings: {
          title: {
            config: { value: `${this.dataModelName} details` },
            directiveType: "literalValue"
          },
          record: {
            config: { type: "_types.ActiveRecord", variableName: "record" },
            directiveType: "getPageVariable"
          },
          description: {
            config: { value: "" },
            directiveType: "literalValue"
          },
          backButtonText: {
            config: { type: "_types.String", value: "Back" },
            directiveType: "literalValue"
          },
          editButtonText: {
            config: { type: "_types.String", value: "Edit" },
            directiveType: "literalValue"
          },
          showBackButton: {
            config: { type: "_types.Boolean", value: true },
            directiveType: "literalValue"
          },
          showEditButton: {
            config: { type: "_types.Boolean", value: true },
            directiveType: "literalValue"
          },
          editControllerPage: {
            config: { type: "_types.EditPage", value: this.editPageId },
            directiveType: "literalValue"
          },
          updateControllerPage: {
            config: { type: "_types.UpdatePage", value: this.updatePageId },
            directiveType: "literalValue"
          }
        }
      }
    ];

    return {
      viewgraph: {
        enabled: true,
        containerStructure
      }
    };
  }

  generateShowPageLogic() {
    return {
      flowgraph: [
        {
          config: {
            name: "record",
            type: "_types.ActiveRecord",
            initialValue: {
              id: uuidv4(),
              config: {
                method: "find",
                arguments: {
                  id: {
                    id: uuidv4(),
                    config: { name: "id", arguments: {} },
                    directiveType: "getArgument"
                  }
                },
                modelName: this.dataModelName
              },
              directiveType: "executeModelMethod"
            }
          },
          directiveType: "declarePageVariable"
        }
      ],
      parameters: []
    };
  }

  generateNewPage() {
    return {
      id: this.newPageId,
      name: `New ${this.dataModelName}`,
      description: `Render a page to create a new ${this.dataModelName} record.`,
      pageType: "new",
      dataModelId: this.dataModel.id,
      relativePath: "/new",
      userInterface: this.generateNewPageUI(),
      initializationLogic: this.generateNewPageLogic()
    };
  }

  generateNewPageUI() {
    const containerStructure = this.generateDefaultContainerStructure(this.newPageId);
    containerStructure.subcontainers[0].components = [
      {
        id: uuidv4(),
        reactions: {},
        blueprintName: "NewRecordForm",
        propertiesBindings: {
          record: {
            config: { type: "_types.ActiveRecord", variableName: "record" },
            directiveType: "getPageVariable"
          },
          controllerPageId: {
            config: { type: "_types.CreatePage", value: this.createPageId },
            directiveType: "literalValue"
          },
          title: {
            config: { type: "_types.String", value: `New ${this.dataModelName}` },
            directiveType: "literalValue"
          },
          description: {
            config: { type: "_types.String", value: `Create a new ${this.dataModelName.toLowerCase()}` },
            directiveType: "literalValue"
          },
          backButtonText: {
            config: { type: "_types.String", value: "Back" },
            directiveType: "literalValue"
          }
        }
      }
    ];

    return {
      viewgraph: {
        enabled: true,
        containerStructure
      }
    };
  }

  generateNewPageLogic() {
    return {
      flowgraph: [
        {
          config: {
            name: "record",
            type: "_types.ActiveRecord",
            initialValue: {
              id: uuidv4(),
              config: {
                method: "new",
                arguments: {},
                modelName: this.dataModelName
              },
              directiveType: "executeModelMethod"
            }
          },
          directiveType: "declarePageVariable"
        }
      ],
      parameters: []
    };
  }

  generateEditPage() {
    return {
      id: this.editPageId,
      name: `Edit ${this.dataModelName}`,
      description: `Render a form to edit an existing ${this.dataModelName} record.`,
      pageType: "edit",
      dataModelId: this.dataModel.id,
      relativePath: "/:id/edit",
      userInterface: this.generateEditPageUI(),
      initializationLogic: this.generateEditPageLogic()
    };
  }

  generateEditPageUI() {
    const containerStructure = this.generateDefaultContainerStructure(this.editPageId);
    containerStructure.subcontainers[0].components = [
      {
        id: uuidv4(),
        reactions: {},
        blueprintName: "EditRecordForm",
        propertiesBindings: {
          record: {
            config: { type: "_types.ActiveRecord", variableName: "record" },
            directiveType: "getPageVariable"
          },
          title: {
            config: { type: "_types.String", value: `Edit ${this.dataModelName}` },
            directiveType: "literalValue"
          },
          updateControllerPage: {
            config: { type: "_types.UpdatePage", value: this.updatePageId },
            directiveType: "literalValue"
          },
          showDeleteButton: {
            config: { type: "_types.Boolean", value: true },
            directiveType: "literalValue"
          },
          deleteButtonText: {
            config: { type: "_types.String", value: "Delete" },
            directiveType: "literalValue"
          },
          deleteControllerPage: {
            config: { type: "_types.DestroyPage", value: this.destroyPageId },
            directiveType: "literalValue"
          },
          showBackButton: {
            config: { type: "_types.Boolean", value: true },
            directiveType: "literalValue"
          },
          backButtonText: {
            config: { type: "_types.String", value: "Back" },
            directiveType: "literalValue"
          }
        }
      }
    ];

    return {
      viewgraph: {
        enabled: true,
        containerStructure
      }
    };
  }

  generateEditPageLogic() {
    return {
      flowgraph: [
        {
          config: {
            name: "record",
            type: "_types.ActiveRecord",
            initialValue: {
              id: uuidv4(),
              config: {
                method: "find",
                arguments: {
                  id: {
                    id: uuidv4(),
                    config: { name: "id", arguments: {} },
                    directiveType: "getArgument"
                  }
                },
                modelName: this.dataModelName
              },
              directiveType: "executeModelMethod"
            }
          },
          directiveType: "declarePageVariable"
        }
      ],
      parameters: []
    };
  }

  generateCreatePage() {
    return {
      id: this.createPageId,
      name: `${this.dataModelName} Create`,
      description: `Run server-side logic to create a new ${this.dataModelName.toLowerCase()} record.`,
      pageType: "create",
      dataModelId: this.dataModel.id,
      relativePath: "/",
      userInterface: {
        disabled: true,
      },
      initializationLogic: this.generateCreatePageLogic()
    };
  }

  generateCreatePageLogic() {
    return {
      flowgraph: [
        {
          config: {
            name: "record",
            type: "_types.ActiveRecord",
            initialValue: {
              id: uuidv4(),
              config: {
                method: "create",
                arguments: {
                  attributes: {
                    id: uuidv4(),
                    config: {
                      type: "_types.Dictionary",
                      value: this.dataModel.fields?.reduce((acc, field) => {
                        acc[field.name] = {
                          id: uuidv4(),
                          config: { name: field.name },
                          directiveType: "getArgument"
                        };
                        return acc;
                      }, {} as Record<string, any>),
                    },
                    directiveType: "literalValue"
                  }
                },
                modelName: this.dataModelName
              },
              directiveType: "executeModelMethod"
            }
          },
          directiveType: "declarePageVariable"
        }
      ],
      parameters: this.dataModel.fields?.map(field => ({
        id: uuidv4(),
        name: field.name,
        dataType: field.dataType,
        description: field.description
      })),
      renderAction: {
        type: "redirectToPage",
        redirectTo: {
          pageId: this.indexPageId,
          pageArguments: {}
        }
      }

    };
  }

  generateUpdatePage() {
    return {
      id: this.updatePageId,
      name: `${this.dataModelName} Update`,
      description: `Run server-side logic to update an existing ${this.dataModelName.toLowerCase()} record.`,
      pageType: "update",
      dataModelId: this.dataModel.id,
      relativePath: "/:id",
      userInterface: {
        enabled: false
      },
      initializationLogic: this.generateUpdatePageLogic()
    };
  }

  generateUpdatePageLogic() {
    return {
      flowgraph: [
        {
          id: uuidv4(),
          directiveType: "executeModelMethod",
          config: {
            modelName: this.dataModelName,
            method: "update",
            arguments: {
              id: {
                id: uuidv4(),
                config: { name: "id" },
                directiveType: "getArgument"
              },
              attributes: {
                id: uuidv4(),
                directiveType: "literalValue",
                config: {
                  type: "_types.Dictionary",
                  value: this.dataModel.fields?.reduce((acc, field) => {
                    acc[field.name] = {
                      id: uuidv4(),
                      config: { name: field.name },
                      directiveType: "getArgument"
                    };
                    return acc;
                  }, {} as Record<string, any>),
                }
              }
            },
          },
        }
      ],
      parameters: this.dataModel.fields?.map(field => ({
        id: uuidv4(),
        name: field.name,
        dataType: field.dataType,
        description: field.description
      })),
      renderAction: {
        type: "redirectToPage",
        redirectTo: {
          pageId: this.indexPageId,
          pageArguments: {}
        }
      }
    };
  }

  generateDestroyPage() {
    return {
      id: this.destroyPageId,
      name: `${this.dataModelName} Destroy`,
      description: `Run server-side logic to destroy an existing ${this.dataModelName.toLowerCase()} record.`,
      pageType: "destroy",
      dataModelId: this.dataModel.id,
      relativePath: "/:id",
      userInterface: {
        enabled: false
      },
      initializationLogic: this.generateDestroyPageLogic()
    };
  }

  generateDestroyPageLogic() {
    return {
      flowgraph: [
        {
          id: uuidv4(),
          config: {
            method: "destroy",
            arguments: {
              id: {
                id: uuidv4(),
                config: { name: "id" },
                directiveType: "getArgument"
              }
            },
            modelName: this.dataModelName
          },
          directiveType: "executeModelMethod"
        }
      ],
      parameters: [
        {
          id: uuidv4(),
          name: "id",
          dataType: "_types.Number",
          description: "The ID of the record to destroy"
        }
      ],
      renderAction: {
        type: "redirectToPage",
        redirectTo: {
          pageId: this.indexPageId,
          pageArguments: {}
        }
      }
    };
  }
}