import React, { useState, useEffect, ErrorInfo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  ComponentInstance,
  ComponentBlueprint,
} from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import PropertyEditor from "./PropertyEditor/PropertyEditor";
import ListPropertyEditor from "./ListPropertyEditor";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import ReactionEditor from "./ReactionEditor";

interface ComponentPropertiesPanelProps {
  selectedComponentId: string | null;
  selectedComponentKeypath: string | null;
  availableComponentBlueprints: ComponentBlueprint[];
  onDeleteComponent: (componentId: string) => void;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const ComponentPropertiesPanel: React.FC<ComponentPropertiesPanelProps> = ({
  selectedComponentId,
  selectedComponentKeypath,
  availableComponentBlueprints,
  onDeleteComponent,
}) => {
  const { getFragment, setFragment, getPageMethods } = useAppDescriptorStore();
  const { pageId } = useParams();
  const [pageVariables, setPageVariables] = useState<string[]>([]);

  useEffect(() => {
    const initLogic = getFragment("initializationLogic.flowgraph");
    if (initLogic) {
      const variables = extractDeclaredVariables(initLogic);
      setPageVariables(variables);
    }
  }, [getFragment]);

  if (!selectedComponentKeypath) {
    return <Box p={4}>No component selected</Box>;
  }

  const selectedComponent = getFragment(selectedComponentKeypath) as
    | ComponentInstance
    | undefined;

  if (!selectedComponent) {
    return (
      <Box p={4}>Selected component not found: {selectedComponentKeypath}</Box>
    );
  }

  const componentBlueprint = availableComponentBlueprints.find(
    (bp) => bp.name === selectedComponent.blueprintName
  );

  if (!componentBlueprint) {
    return (
      <Box p={4}>
        Component blueprint not found for: {selectedComponent.blueprintName}
      </Box>
    );
  }

  const handleDomIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFragment(`${selectedComponentKeypath}/domId`, e.target.value);
  };

  const handlePropertyChange = (propertyName: string, newValue: any) => {
    if (selectedComponentKeypath) {
      if (componentBlueprint.properties) {
        setFragment(
          `${selectedComponentKeypath}/propertiesBindings/${propertyName}`,
          {
            directiveType: "literalValue",
            config: {
              type: "Object",
              value: newValue,
            },
          }
        );
      } else if (componentBlueprint.props) {
        setFragment(
          `${selectedComponentKeypath}/props/${propertyName}`,
          newValue
        );
      }
    }
  };

  const renderPropertyBinding = (property: any) => {
    const propertyType = property.schema?.type || property.dataType;
    const propertyName = property.name;
    const currentValue = componentBlueprint.properties
      ? selectedComponent.propertiesBindings?.[propertyName]?.config?.value
      : selectedComponent.props?.[propertyName];

    if (propertyType === "Object" || propertyType === "_types.Object") {
      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <Textarea
            value={JSON.stringify(currentValue || {}, null, 2)}
            onChange={(e) => {
              try {
                const newValue = JSON.parse(e.target.value);
                handlePropertyChange(propertyName, newValue);
              } catch (error) {
                // Optionally handle JSON parse errors
                console.error("Invalid JSON:", error);
              }
            }}
            placeholder={`Enter ${propertyName} as JSON`}
            rows={6}
            fontFamily="monospace"
          />
        </Box>
      );
    }

    if (propertyType === "_types.List" || propertyType === "Array") {
      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <ListPropertyEditor
            schema={property}
            keypath={
              componentBlueprint.properties
                ? `${selectedComponentKeypath}/propertiesBindings/${propertyName}`
                : `${selectedComponentKeypath}/props/${propertyName}`
            }
            value={currentValue || []}
          />
        </Box>
      );
    }

    return (
      <Box key={propertyName}>
        <Text fontWeight="bold" mb={1}>
          {propertyName}
        </Text>
        <PropertyEditor
          schema={property}
          keypath={
            componentBlueprint.properties
              ? `${selectedComponentKeypath}/propertiesBindings/${propertyName}`
              : `${selectedComponentKeypath}/props/${propertyName}`
          }
          value={currentValue}
          onChange={(newValue) => handlePropertyChange(propertyName, newValue)}
        />
      </Box>
    );
  };

  const renderReactionEditor = (event: any) => {
    return (
      <ErrorBoundary key={event.id}>
        <ReactionEditor
          event={event}
          selectedComponentKeypath={selectedComponentKeypath}
          selectedComponent={selectedComponent}
          pageId={pageId}
          getPageMethods={getPageMethods}
        />
      </ErrorBoundary>
    );
  };

  const getComponentProperties = () => {
    if (componentBlueprint.properties) {
      return componentBlueprint.properties;
    }
    if (componentBlueprint.props) {
      return componentBlueprint.props;
    }
    return [];
  };

  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md">{componentBlueprint.name} Properties</Heading>

        <Heading size="sm">Properties</Heading>
        {getComponentProperties().length > 0 ? (
          <VStack spacing={4} align="stretch">
            {getComponentProperties().map(renderPropertyBinding)}
          </VStack>
        ) : (
          <Text>No editable properties for this component.</Text>
        )}

        <Heading size="sm">Events</Heading>
        {componentBlueprint.events && componentBlueprint.events.length > 0 ? (
          <List spacing={2}>
            {componentBlueprint.events.map((event) => (
              <ListItem key={event.id}>{renderReactionEditor(event)}</ListItem>
            ))}
          </List>
        ) : (
          <Text>No events for this component.</Text>
        )}

        <Box>
          <Text fontWeight="bold" mb={1}>
            DOM ID
          </Text>
          <Input
            placeholder="DOM ID"
            value={selectedComponent.domId || ""}
            onChange={handleDomIdChange}
          />
        </Box>
      </VStack>
    </Box>
  );
};

function extractDeclaredVariables(initLogic: any): string[] {
  return [];
}

export default ComponentPropertiesPanel;
