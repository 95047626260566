import React from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconServer,
  IconChevronUp,
  IconChevronDown,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";

interface ServiceCallNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
}

export function ServiceCallNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
}: ServiceCallNodeProps) {
  const iconColor = "rgb(138, 180, 248)";

  return (
    <Box
      borderWidth={1}
      borderColor={iconColor}
      borderRadius="md"
      bg="#202124"
      width="350px"
      overflow="hidden"
    >
      <HStack p={3} bg={`${iconColor}20`} justifyContent="space-between">
        <HStack>
          <IconButton
            icon={<IconMinus size={16} />}
            aria-label="Collapse"
            size="sm"
            variant="ghost"
            color={colors.grayDark.gray12}
          />
          <Text fontWeight="medium" color="white">
            Service Call
          </Text>
        </HStack>
        <HStack>
          {!isFirst && (
            <Tooltip label="Move Up" aria-label="Move Up Tooltip">
              <IconButton
                icon={<IconChevronUp size={16} />}
                aria-label="Move Up"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("up")}
              />
            </Tooltip>
          )}
          {!isLast && (
            <Tooltip label="Move Down" aria-label="Move Down Tooltip">
              <IconButton
                icon={<IconChevronDown size={16} />}
                aria-label="Move Down"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("down")}
              />
            </Tooltip>
          )}
          <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
            <IconButton
              icon={<IconTrash size={16} />}
              aria-label="Delete Node"
              size="sm"
              variant="ghost"
              color={colors.red.red6}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteNode(node.id);
              }}
              _hover={{ bg: colors.red.red3 }}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <VStack align="stretch" p={3} bg="#303134" spacing={2}>
        <HStack>
          <IconServer size={16} color={iconColor} />
          <Text color="white" fontWeight="medium">
            Mock API Call
          </Text>
        </HStack>
        <Text color={colors.grayDark.gray11} fontSize="sm">
          GET https://api.example.com/data
        </Text>
        <Text color={colors.grayDark.gray11} fontSize="sm">
          Response: JSON
        </Text>
      </VStack>
    </Box>
  );
}
