import { generateFlowgraph } from "@/bundles/FlowgraphEditor/utils/generateFlowgraph";
import {
  convertContainerToNodeTree,
  transformFormat,
} from "../../editors/ViewgraphEditor/utils/convertAppDescriptors";

export const generateConvertedDescriptor = (
  getFragment: any,
  getPage: any,
  getModelByKeypath: any
) => {
  const pages = getFragment("/essentials/pageGroups") || [];
  const firstPage = pages.length ? getPage(pages[0].pages[0].id) : null;

  if (!firstPage?.pageGroup) {
    return null;
  }

  const dataModel = firstPage?.pageGroup?.dataModelId
    ? getModelByKeypath(
        `/essentials/dataModels/id:${firstPage.pageGroup.dataModelId}`
      )
    : null;

  const allPagesInGroup =
    getFragment(`/essentials/pageGroups/id:${firstPage.pageGroup.id}/pages`) ||
    [];

  const transformedPages = allPagesInGroup
    .map((pageMeta: { id: string }) => {
      const fullPage = getPage(pageMeta.id);
      if (!fullPage) return null;

      const flowgraphNodes =
        fullPage.initializationLogic?.flowgraph?.sequence || [];

      const dataVariables = flowgraphNodes
        .filter(
          (node) =>
            node.name === "Variable" &&
            node.parameters?.mode === "Set" &&
            node.parameters?.isDataVariable === true
        )
        .map((node) => ({
          name: node.parameters.id,
          schema: {
            type: "Collection",
            dataModel: node.parameters.value,
          },
          description: "The collection to display",
        }));

      return {
        name: fullPage.name,
        relativePath: fullPage.relativePath,
        pageType: fullPage.pageType,
        dataVariables: dataVariables,
        controllerAction: {
          name: fullPage.controllerAction?.name || "index",
          flowgraph: generateFlowgraph(
            flowgraphNodes.map((node) => ({
              id: node.id,
              name: node.name,
              parameters: node.parameters,
              childrenYes: node.childrenYes || [],
              childrenNo: node.childrenNo || [],
              functionCall: node.functionCall,
              condition: node.condition,
            }))
          ),
        },
        viewgraph: {
          nodeTree: fullPage.userInterface?.viewgraph?.containerStructure
            ? convertContainerToNodeTree(
                fullPage.userInterface.viewgraph.containerStructure
              )
            : { $element: "div", $children: [] },
        },
      };
    })
    .filter(Boolean);

  return {
    ...transformFormat(firstPage),
    dataModels: dataModel
      ? [
          {
            name: dataModel.name,
            description:
              dataModel.description || `A ${dataModel.name.toLowerCase()}`,
            fields:
              dataModel.fields?.map((field) => ({
                name: field.name,
                databaseType:
                  field.dataType === "_types.String"
                    ? "string"
                    : field.dataType,
              })) || [],
          },
        ]
      : [],
    pageGroups: [
      {
        ...transformFormat(firstPage).pageGroups[0],
        pages: transformedPages,
      },
    ],
  };
};
