import React from "react";
import {
  Box,
  Text,
  useDisclosure,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { FunctionPickerModal } from "./FunctionPickerModal";
import { ConditionalNode, FunctionDescriptor } from "../types";
import {
  IconFunction,
  IconTrash,
  IconEdit,
  IconChevronUp,
  IconChevronDown,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { useState } from "react";
import { PropertiesPanel } from "./PropertiesPanel";
import { useFlowgraphStore } from "../stores/FlowgraphStore";

interface FormulaFunctionNodeProps {
  node: ConditionalNode;
  onUpdate: (updatedNode: ConditionalNode) => void;
  onDeleteNode: (nodeId: string) => void;
  isSelected: boolean;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  availableVariables: string[];
}

export function FormulaFunctionNode({
  node,
  onUpdate,
  onDeleteNode,
  isSelected,
  onReorder,
  isFirst,
  isLast,
  availableVariables,
}: FormulaFunctionNodeProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedField, setSelectedField] = useState<{
    paramName: string;
    value: string;
  } | null>(null);
  const { getAllFunctions } = useFlowgraphStore();

  const iconColor = "rgb(138, 180, 248)";

  const handleSelectFunction = (func: FunctionDescriptor) => {
    const updatedNode = {
      ...node,
      functionCall: func.name,
      parameters: func.parameters.reduce((acc, param) => {
        acc[param.name] = "";
        return acc;
      }, {} as Record<string, string>),
    };
    onUpdate(updatedNode);
    onClose();
  };

  const handleParameterClick = (paramName: string, value: string) => {
    setSelectedField({ paramName, value });
  };

  const handleFieldUpdate = (nodeId: string, field: string, value: string) => {
    const updatedNode = {
      ...node,
      parameters: {
        ...node.parameters,
        [field]: value,
      },
    };
    onUpdate(updatedNode);
  };

  const currentFunction = getAllFunctions().find(
    (func) => func.name === node.functionCall
  );

  return (
    <Box position="relative">
      <Box
        borderWidth={1}
        borderColor={iconColor}
        borderRadius="md"
        bg="#202124"
        width="350px"
        overflow="hidden"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <Flex
          alignItems="center"
          p={3}
          bg={`${iconColor}20`}
          justifyContent="space-between"
        >
          <HStack>
            <Icon as={IconFunction} color={iconColor} boxSize={5} mr={2} />
            <Text fontWeight="medium" color="white">
              Formula Function
            </Text>
          </HStack>
          <HStack>
            {!isFirst && (
              <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                <IconButton
                  icon={<IconChevronUp size={16} />}
                  aria-label="Move Up"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("up")}
                />
              </Tooltip>
            )}
            {!isLast && (
              <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                <IconButton
                  icon={<IconChevronDown size={16} />}
                  aria-label="Move Down"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("down")}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
              <IconButton
                icon={<IconTrash size={16} />}
                aria-label="Delete Node"
                size="sm"
                variant="ghost"
                color={colors.red.red6}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteNode(node.id);
                }}
                _hover={{ bg: colors.red.red3 }}
              />
            </Tooltip>
          </HStack>
        </Flex>

        <VStack align="stretch" p={3} bg="#303134" spacing={2}>
          <Flex
            width="100%"
            bg="#3c4043"
            color={iconColor}
            borderRadius="md"
            alignItems="center"
            position="relative"
            role="group"
            onClick={!node.functionCall ? onOpen : undefined}
            cursor={!node.functionCall ? "pointer" : "default"}
          >
            <Text flex={1} p={2}>
              {node.functionCall || "Pick function"}
            </Text>
            {node.functionCall && (
              <Tooltip label="Edit Function" aria-label="Edit Function Tooltip">
                <IconButton
                  icon={<IconEdit size={16} />}
                  aria-label="Edit Function"
                  size="sm"
                  variant="ghost"
                  color={iconColor}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpen();
                  }}
                  position="absolute"
                  right={2}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                />
              </Tooltip>
            )}
          </Flex>
          {currentFunction &&
            currentFunction.parameters.map((param) => (
              <Flex
                key={param.name}
                width="100%"
                bg="#3c4043"
                color="white"
                borderRadius="md"
                alignItems="center"
                onClick={() =>
                  handleParameterClick(
                    param.name,
                    node.parameters[param.name] || ""
                  )
                }
                cursor="pointer"
                _hover={{ bg: "#4c5054" }}
              >
                <Text flex={1} p={2}>
                  {param.name}:
                </Text>
                <Text p={2} color={iconColor}>
                  {node.parameters[param.name]?.startsWith("$")
                    ? `Variable: ${node.parameters[param.name].substring(1)}`
                    : node.parameters[param.name] || "(empty)"}
                </Text>
              </Flex>
            ))}
        </VStack>
      </Box>

      <FunctionPickerModal
        isOpen={isOpen}
        onClose={onClose}
        onSelect={handleSelectFunction}
      />

      {selectedField && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: selectedField.paramName }}
          fieldValue={selectedField.value}
          onClose={() => setSelectedField(null)}
          onFieldUpdate={handleFieldUpdate}
          availableVariables={availableVariables}
        />
      )}
    </Box>
  );
}
