import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  Select as ChakraSelect,
  Input,
  Checkbox,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { ContainerStructure } from "./ViewgraphEditor";

interface LayoutModeEditorProps {
  keypath: string;
  canvasSize: { width: number; height: number };
  onLayoutChange: (layout: ContainerStructure) => void;
}

export const layoutTemplates = [
  { name: "Single Container", value: "single" },
  { name: "Header, Body, Footer", value: "header-body-footer" },
  { name: "2x2 Grid", value: "grid-2x2" },
  { name: "Sidebar with Content", value: "sidebar-content" },
  { name: "4x4 Grid", value: "grid-4x4" },
  { name: "Three Columns", value: "three-columns" },
  { name: "Header, Sidebar, Content", value: "header-sidebar-content" },
  { name: "Two Rows", value: "two-rows" },
  { name: "Header, Two Columns", value: "header-two-columns" },
  { name: "Footer, Two Columns", value: "footer-two-columns" },
];

const LayoutModeEditor: React.FC<LayoutModeEditorProps> = ({
  keypath,
  canvasSize = { width: 800, height: 600 },
  onLayoutChange,
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [selectedTemplate, setSelectedTemplate] = useState("single");
  const [currentLayout, setCurrentLayout] = useState<ContainerStructure | null>(
    null
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const existingLayout = getFragment(
      `${keypath}/containerStructure`
    ) as ContainerStructure;
    setCurrentLayout(
      existingLayout || {
        id: "root",
        layoutDirection: "vertical",
        size: { value: 1, unit: "fr" },
        components: [],
        subcontainers: [],
        isScrollable: true,
        isLayoutApplied: false,
      }
    );
  }, [keypath, getFragment]);

  const applyTemplate = () => {
    let newLayout: ContainerStructure;

    const createContainer = (
      id: string,
      layoutDirection: "horizontal" | "vertical",
      size: { value: number; unit: "px" | "fr" },
      isScrollable: boolean
    ): ContainerStructure => ({
      id,
      layoutDirection,
      size,
      subcontainers: [],
      isScrollable,
      components: [],
      isLayoutApplied: true,
    });

    switch (selectedTemplate) {
      case "header-body-footer":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "header",
              "horizontal",
              { value: 80, unit: "px" },
              false
            ),
            createContainer("body", "vertical", { value: 1, unit: "fr" }, true),
            createContainer(
              "footer",
              "horizontal",
              { value: 60, unit: "px" },
              false
            ),
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "grid-2x2":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            {
              id: "row1",
              layoutDirection: "horizontal",
              size: { value: 1, unit: "fr" },
              components: [],
              subcontainers: [
                createContainer(
                  "cell1",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
                createContainer(
                  "cell2",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
              ],
              isScrollable: false,
              isLayoutApplied: true,
            },
            {
              id: "row2",
              layoutDirection: "horizontal",
              size: { value: 1, unit: "fr" },
              components: [],
              subcontainers: [
                createContainer(
                  "cell3",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
                createContainer(
                  "cell4",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
              ],
              isScrollable: false,
              isLayoutApplied: true,
            },
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "sidebar-content":
        newLayout = {
          id: "root",
          layoutDirection: "horizontal",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "sidebar",
              "vertical",
              { value: 250, unit: "px" },
              true
            ),
            createContainer(
              "content",
              "vertical",
              { value: 1, unit: "fr" },
              true
            ),
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "grid-4x4":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: Array.from({ length: 4 }, (_, rowIndex) => ({
            id: `row${rowIndex + 1}`,
            layoutDirection: "horizontal",
            size: { value: 1, unit: "fr" },
            components: [],
            subcontainers: Array.from({ length: 4 }, (_, colIndex) =>
              createContainer(
                `cell${rowIndex * 4 + colIndex + 1}`,
                "vertical",
                { value: 1, unit: "fr" },
                true
              )
            ),
            isScrollable: false,
            isLayoutApplied: true,
          })),
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "three-columns":
        newLayout = {
          id: "root",
          layoutDirection: "horizontal",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "column1",
              "vertical",
              { value: 1, unit: "fr" },
              true
            ),
            createContainer(
              "column2",
              "vertical",
              { value: 1, unit: "fr" },
              true
            ),
            createContainer(
              "column3",
              "vertical",
              { value: 1, unit: "fr" },
              true
            ),
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "header-sidebar-content":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "header",
              "horizontal",
              { value: 80, unit: "px" },
              false
            ),
            {
              id: "main",
              layoutDirection: "horizontal",
              size: { value: 1, unit: "fr" },
              components: [],
              subcontainers: [
                createContainer(
                  "sidebar",
                  "vertical",
                  { value: 250, unit: "px" },
                  true
                ),
                createContainer(
                  "content",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
              ],
              isScrollable: false,
              isLayoutApplied: true,
            },
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "two-rows":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "row1",
              "horizontal",
              { value: 1, unit: "fr" },
              true
            ),
            createContainer(
              "row2",
              "horizontal",
              { value: 1, unit: "fr" },
              true
            ),
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "header-two-columns":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer(
              "header",
              "horizontal",
              { value: 80, unit: "px" },
              false
            ),
            {
              id: "main",
              layoutDirection: "horizontal",
              size: { value: 1, unit: "fr" },
              components: [],
              subcontainers: [
                createContainer(
                  "column1",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
                createContainer(
                  "column2",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
              ],
              isScrollable: false,
              isLayoutApplied: true,
            },
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      case "footer-two-columns":
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            {
              id: "main",
              layoutDirection: "horizontal",
              size: { value: 1, unit: "fr" },
              components: [],
              subcontainers: [
                createContainer(
                  "column1",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
                createContainer(
                  "column2",
                  "vertical",
                  { value: 1, unit: "fr" },
                  true
                ),
              ],
              isScrollable: false,
              isLayoutApplied: true,
            },
            createContainer(
              "footer",
              "horizontal",
              { value: 60, unit: "px" },
              false
            ),
          ],
          isScrollable: false,
          isLayoutApplied: true,
        };
        break;
      default:
        newLayout = {
          id: "root",
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [],
          subcontainers: [
            createContainer("UI", "vertical", { value: 1, unit: "fr" }, true),
          ],
          isScrollable: true,
          isLayoutApplied: true,
        };
    }

    setCurrentLayout(newLayout);
  };

  const updateContainerProperty = (
    containerId: string,
    property: string,
    value: any
  ) => {
    const updateContainer = (
      container: ContainerStructure
    ): ContainerStructure => {
      if (container.id === containerId) {
        return { ...container, [property]: value };
      }
      return {
        ...container,
        subcontainers: container.subcontainers.map(updateContainer),
      };
    };

    const updatedLayout = updateContainer(currentLayout!);
    setCurrentLayout(updatedLayout);
  };

  const renderContainerEditor = (container: ContainerStructure, depth = 0) => (
    <Box
      key={container.id}
      ml={depth * 4}
      mb={4}
      p={2}
      borderLeft="2px"
      borderColor="gray.200"
    >
      <Text fontWeight="bold">{container.id}</Text>
      <HStack spacing={4} mt={2}>
        <ChakraSelect
          value={container.layoutDirection}
          onChange={(e) =>
            updateContainerProperty(
              container.id,
              "layoutDirection",
              e.target.value
            )
          }
        >
          <option value="horizontal">Horizontal</option>
          <option value="vertical">Vertical</option>
        </ChakraSelect>
        <Input
          type="number"
          value={container.size.value}
          onChange={(e) =>
            updateContainerProperty(container.id, "size", {
              ...container.size,
              value: Number(e.target.value),
            })
          }
          width="100px"
        />
        <ChakraSelect
          value={container.size.unit}
          onChange={(e) =>
            updateContainerProperty(container.id, "size", {
              ...container.size,
              unit: e.target.value as "px" | "fr",
            })
          }
          width="80px"
        >
          <option value="px">px</option>
          <option value="fr">fr</option>
        </ChakraSelect>
        <Checkbox
          isChecked={container.isScrollable}
          onChange={(e) =>
            updateContainerProperty(
              container.id,
              "isScrollable",
              e.target.checked
            )
          }
        >
          Scrollable
        </Checkbox>
      </HStack>
      {container.subcontainers.map((subcontainer) =>
        renderContainerEditor(subcontainer, depth + 1)
      )}
    </Box>
  );

  const renderLayoutPreview = () => {
    if (!canvasSize) {
      console.error("Canvas size is not defined");
      return null;
    }

    const renderContainer = (container: ContainerStructure, depth = 0) => {
      const style: React.CSSProperties = {
        border: "1px solid #ccc",
        padding: "4px",
        display: "flex",
        flexDirection:
          container.layoutDirection === "vertical" ? "column" : "row",
        overflow: container.isScrollable ? "auto" : "hidden",
      };

      if (container.size.unit === "fr") {
        style.flex = container.size.value;
      } else {
        style[
          container.layoutDirection === "vertical" ? "height" : "width"
        ] = `${container.size.value}px`;
      }

      return (
        <Box key={container.id} style={style}>
          <Text fontSize="xs" fontWeight="bold">
            {container.id}
          </Text>
          {container.subcontainers.map((subcontainer) =>
            renderContainer(subcontainer, depth + 1)
          )}
        </Box>
      );
    };

    return (
      <Box
        width={`${canvasSize.width}px`}
        height={`${canvasSize.height}px`}
        border="1px solid"
        borderColor="gray.300"
        overflow="hidden"
      >
        {currentLayout && renderContainer(currentLayout)}
      </Box>
    );
  };

  const handleSaveLayout = () => {
    if (currentLayout) {
      const ensureLayoutApplied = (
        container: ContainerStructure
      ): ContainerStructure => ({
        ...container,
        isLayoutApplied: true,
        subcontainers: container.subcontainers.map(ensureLayoutApplied),
      });

      const finalLayout = ensureLayoutApplied(currentLayout);
      onLayoutChange(finalLayout);

      if (isOpen) {
        onClose();
      }
    }
  };

  return (
    <VStack spacing={1} align="stretch">
      <HStack>
        <ChakraSelect
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          {layoutTemplates.map((template) => (
            <option key={template.value} value={template.value}>
              {template.name}
            </option>
          ))}
        </ChakraSelect>
        <Button colorScheme="blue" onClick={onOpen}>
          Apply Template
        </Button>
      </HStack>
      <Box display="flex">
        <Box flex={1} pr={4}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Container Structure
          </Text>
          {currentLayout && renderContainerEditor(currentLayout)}
        </Box>
        <Box flex={1}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Layout Preview
          </Text>
          {renderLayoutPreview()}
        </Box>
      </Box>
      <Button colorScheme="green" onClick={handleSaveLayout}>
        Save Layout
      </Button>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Apply Template
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to apply the selected template? This will
              overwrite the current layout.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  applyTemplate();
                  onClose();
                }}
                ml={3}
              >
                Apply
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default LayoutModeEditor;
