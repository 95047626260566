import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Input,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconChevronUp,
  IconChevronDown,
  IconDatabase,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";
import { PropertiesPanel } from "./PropertiesPanel";
import { useAppDescriptorStore } from "../../DescriptorEditor/stores/appDescriptorStore";

interface ModelMethodNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  onSelectField: (nodeId: string, field: "name" | "returnVariable") => void;
  isSelected: boolean;
  onFieldUpdate: (
    nodeId: string,
    field: "name" | "returnVariable",
    value: string
  ) => void;
  availableVariables: string[];
}

export function ModelMethodNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
  onSelectField,
  isSelected,
  onFieldUpdate,
  availableVariables,
  handleClosePropertiesPanel,
}: ModelMethodNodeProps) {
  const iconColor = "rgb(138, 180, 248)";
  const [modelName, setModelName] = useState(node.parameters?.modelName || "");
  const [recordLimit, setRecordLimit] = useState(
    node.parameters?.recordLimit || 10
  );
  const [returnVariable, setReturnVariable] = useState(
    node.parameters?.returnVariable || ""
  );
  const [methodType, setMethodType] = useState(
    node.parameters?.methodType || "RetrieveAllRecords"
  );
  const [make, setMake] = useState(node.parameters?.make || "");
  const [model, setModel] = useState(node.parameters?.model || "");
  const [recordId, setRecordId] = useState(node.parameters?.recordId || "");

  // Get available data models from the app descriptor store
  const { getFragment } = useAppDescriptorStore();
  const dataModels =
    (getFragment("/essentials/dataModels") as { name: string }[]) || [];
  const availableDataModels = dataModels.map((model) => model.name);

  const handleModelNameUpdate = (value: string) => {
    setModelName(value);
    onFieldUpdate(node.id, "modelName", value);
  };

  return (
    <Box
      borderWidth={1}
      borderColor={iconColor}
      borderRadius="md"
      bg="#202124"
      width="350px"
      overflow="hidden"
      position="relative"
      boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
      _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
    >
      <HStack p={2} bg={`${iconColor}20`} justifyContent="space-between">
        <HStack spacing={2}>
          <IconButton
            icon={<IconMinus size={16} />}
            aria-label="Collapse"
            size="sm"
            variant="ghost"
            color={colors.grayDark.gray12}
          />
          <Text fontWeight="medium" color="white">
            Model Method
          </Text>
        </HStack>
        <HStack spacing={1}>
          {!isFirst && (
            <Tooltip label="Move Up" aria-label="Move Up Tooltip">
              <IconButton
                icon={<IconChevronUp size={16} />}
                aria-label="Move Up"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("up")}
              />
            </Tooltip>
          )}
          {!isLast && (
            <Tooltip label="Move Down" aria-label="Move Down Tooltip">
              <IconButton
                icon={<IconChevronDown size={16} />}
                aria-label="Move Down"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("down")}
              />
            </Tooltip>
          )}
          <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
            <IconButton
              icon={<IconTrash size={16} />}
              aria-label="Delete Node"
              size="sm"
              variant="ghost"
              color={colors.red.red6}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteNode(node.id);
              }}
              _hover={{ bg: colors.red.red3 }}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <VStack align="stretch" p={2} bg="#303134" spacing={1}>
        <HStack
          justifyContent="space-between"
          cursor="pointer"
          onClick={() => onSelectField(node.id, "name")}
        >
          <HStack spacing={1}>
            <IconDatabase size={16} color={iconColor} />
            <Text color="white" fontWeight="medium">
              {modelName || "Select Data Model"}
            </Text>
          </HStack>
          <Text color="gray.900" fontSize="sm">
            Data model
          </Text>
        </HStack>
        <Select
          value={methodType}
          onChange={(e) => {
            const value = e.target.value;
            setMethodType(value);
            onFieldUpdate(node.id, "methodType", value);
          }}
          size="sm"
          textColor="white"
        >
          <option value="RetrieveAllRecords">Retrieve All Records</option>
          <option value="RetrieveRecord">Retrieve Single Record</option>
          <option value="CreateRecord">Create Record</option>
        </Select>
        {methodType === "RetrieveAllRecords" && (
          <HStack>
            <Text color="white" fontSize="sm">
              Limit Records:
            </Text>
            <NumberInput
              value={recordLimit}
              textColor="white"
              onChange={(valueString) => {
                const value = parseInt(valueString, 10);
                setRecordLimit(value);
                onFieldUpdate(node.id, "recordLimit", value.toString());
              }}
              min={1}
              max={100}
              width="80px"
              size="sm"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        )}
        {methodType === "RetrieveRecord" && (
          <HStack>
            <Text color="white" fontSize="sm">
              Record ID:
            </Text>
            <Select
              placeholder="Select source"
              value={recordId}
              onChange={(e) => {
                const value = e.target.value;
                setRecordId(value);
                onFieldUpdate(node.id, "recordId", value);
              }}
              size="sm"
              width="200px"
              textColor="white"
            >
              <option value="KaseyOS/params/id">URL Parameter (id)</option>
              <option value="$payload/id">Payload ID</option>
            </Select>
          </HStack>
        )}
        {methodType === "CreateRecord" && (
          <>
            <HStack>
              <Text color="white" fontSize="sm">
                Make:
              </Text>
              <Input
                value={make}
                onChange={(e) => {
                  const value = e.target.value;
                  setMake(value);
                  onFieldUpdate(node.id, "make", value);
                }}
                size="sm"
                textColor="white"
              />
            </HStack>
            <HStack>
              <Text color="white" fontSize="sm">
                Model:
              </Text>
              <Input
                value={model}
                onChange={(e) => {
                  const value = e.target.value;
                  setModel(value);
                  onFieldUpdate(node.id, "model", value);
                }}
                size="sm"
                textColor="white"
              />
            </HStack>
          </>
        )}
        {(methodType === "RetrieveAllRecords" ||
          methodType === "RetrieveRecord") && (
          <HStack>
            <Text color="white" fontSize="sm">
              Assign return value to:
            </Text>
            <Select
              placeholder="Select variable"
              value={returnVariable}
              textColor="white"
              onChange={(e) => {
                const value = e.target.value;
                setReturnVariable(value);
                onFieldUpdate(node.id, "returnVariable", value);
              }}
              size="sm"
              width="150px"
            >
              {availableVariables.map((variable) => (
                <option key={variable} value={variable}>
                  {variable}
                </option>
              ))}
            </Select>
          </HStack>
        )}
      </VStack>

      {isSelected && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: "name" }}
          fieldValue={modelName}
          onClose={handleClosePropertiesPanel}
          onFieldUpdate={(nodeId, field, value) => {
            handleModelNameUpdate(value);
            handleClosePropertiesPanel();
          }}
          availableVariables={availableVariables}
          availableDataModels={availableDataModels}
        />
      )}
    </Box>
  );
}
