import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  List,
  ListItem,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import {
  IconAbc,
  IconCode,
  IconCube,
  IconMath,
  IconCloud,
  IconBolt,
  IconSearch,
  IconArrowRight,
  IconCone,
} from "@tabler/icons-react";
import { FunctionDescriptor } from "../types";
import { useFlowgraphStore } from "../stores/FlowgraphStore";

type ModeOptions =
  | {
      mode: "addStep";
      keypath: string;
      newStepIndex: number;
    }
  | {
      mode: "setStepFunction";
      keypath: string;
    }
  | {
      mode: "setArgumentBinding";
      keypath: string;
    };

interface SelectBindingModalProps {
  options: ModeOptions;
  isOpen: boolean;
  onClose: () => void;
  onSelect: (optionName: string) => void;
}

export function SelectBindingModal({
  options,
  isOpen,
  onClose,
  onSelect,
}: SelectBindingModalProps) {
  const {
    getAllFunctions,
    getCurrentContext,
    getExpectedSchema,
    addFunctionCallToSequence,
    setToFunction,
    setToStaticValue,
  } = useFlowgraphStore();

  const availableFunctions = getAllFunctions();
  const currentContext = getCurrentContext();
  const expectedSchema = getExpectedSchema(options.keypath);

  const getSubcategories = (category: string): string[] => {
    switch (category) {
      case "Formula function":
        return ["Math", "Text", "Logic", "Date"];
      case "Variable":
      case "Model method":
      case "Service call":
        return ["Empty"];
      default:
        return [];
    }
  };

  const getFunctionsForSubcategory = (
    category: string,
    subcategory: string | null
  ) => {
    switch (category) {
      case "Variable":
        return availableFunctions.filter(
          (func) => func.characteristics?.isVariableFunction
        );
      case "Formula function":
        return availableFunctions.filter(
          (func) =>
            func.characteristics?.isFormulaFunction &&
            func.name.startsWith(subcategory + ".")
        );
      default:
        return [];
    }
  };

  const renderContent = () => {
    return (
      <Box>
        <InputGroup mb={4}>
          <InputLeftElement pointerEvents="none">
            <IconSearch color="gray.300" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup>
        <List spacing={2}>
          {/* Conditional Statement - Enabled */}
          <ListItem
            onClick={() => onSelect("Conditional Statement")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconBolt
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Conditional Statement
          </ListItem>

          {/* Static Value - Enabled */}
          <ListItem
            onClick={() => onSelect("Static Value")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconAbc
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Static Value
          </ListItem>

          {/* Formula function - Enabled */}
          <ListItem
            onClick={() => onSelect("Formula function")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconMath
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Formula function
          </ListItem>

          {/* Service Call - Enabled */}
          <ListItem
            onClick={() => onSelect("Service Call")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconCloud
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Service Call
          </ListItem>

          {/* Model Method - Enabled */}
          <ListItem
            onClick={() => onSelect("Model Method")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconCode
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Model Method
          </ListItem>

          {/* Variable - Enabled */}
          <ListItem
            onClick={() => onSelect("Variable")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconCube
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Variable
          </ListItem>

          <ListItem
            onClick={() => onSelect("Constant")}
            cursor="pointer"
            p={2}
            bg="gray.500"
            borderRadius="md"
            _hover={{ bg: "gray.300" }}
          >
            <IconCone
              size={18}
              style={{ display: "inline", marginRight: "8px" }}
            />
            Constant
          </ListItem>
        </List>
      </Box>
    );
  };

  const onSelectOption = (optionName: string) => {
    if (optionName === "Conditional Statement") {
      onSelectFunctionBinding({
        name: "ConditionalStatement",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    } else if (optionName === "Static Value") {
      onSelectStaticValueBinding("String");
    } else if (optionName === "Formula function") {
      onSelectFunctionBinding({
        name: "Formula function",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    } else if (optionName === "Service Call") {
      onSelectFunctionBinding({
        name: "Service Call",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    } else if (optionName === "Model Method") {
      onSelectFunctionBinding({
        name: "Model Method",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    } else if (optionName === "Variable") {
      onSelectFunctionBinding({
        name: "Variable",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    } else if (optionName === "Constant") {
      onSelectFunctionBinding({
        name: "Constant",
        parameters: [],
        returns: { schema: { type: "void" }, description: "" },
        editorTemplate: [],
      });
    }
  };

  function onSelectFunctionBinding(func: FunctionDescriptor) {
    if (options.mode === "addStep") {
      addFunctionCallToSequence(
        options.keypath,
        func.name,
        options.newStepIndex
      );
    } else if (options.mode === "setStepFunction") {
      setToFunction(options.keypath, func.name);
    } else if (options.mode === "setArgumentBinding") {
      setToStaticValue(options.keypath, func.name);
    }
    onClose();
  }

  function onSelectStaticValueBinding(dataType: string) {
    if (options.mode === "addStep") {
      addFunctionCallToSequence(
        options.keypath,
        "Static Value",
        options.newStepIndex
      );
    } else if (options.mode === "setArgumentBinding") {
      setToStaticValue(options.keypath, dataType);
    }
    onClose();
  }

  function schemasAreCompatible(
    returnSchema: any,
    expectedSchema: any
  ): boolean {
    if (!returnSchema || !expectedSchema) return true;
    return returnSchema.type === expectedSchema.type;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>
          <Text fontSize="xl">Select Binding:</Text>
        </ModalHeader>
        <ModalBody pb={6}>
          {renderContent()}
          <Flex justifyContent="flex-end" mt={4}>
            <Button
              rightIcon={<IconArrowRight />}
              colorScheme="blue"
              onClick={() => onSelect("Selected Option")}
            >
              Select
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
