import React, { useState, useCallback } from "react";
import EditableContainerStructure from "./EditableContainerStructure";
import ActionMenu from "./ActionMenu";
import ContainerPropertiesPanel from "./ContainerPropertiesPanel";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { ContainerStructure, Viewgraph } from "./ViewgraphEditor";

interface ContainerModeEditorProps {
  keypath: string;
}

const ContainerModeEditor: React.FC<ContainerModeEditorProps> = ({
  keypath,
}) => {
  const { getFragment } = useAppDescriptorStore();
  const viewgraph = getFragment(keypath) as Viewgraph;
  const [selectedKeypath, setSelectedKeypath] = useState<string | null>(null);
  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const selectedContainer = selectedKeypath
    ? (getFragment(selectedKeypath) as ContainerStructure)
    : null;

  const handleSelectContainer = (newKeypath: string | null) => {
    setSelectedKeypath(newKeypath);
    forceUpdate();
  };

  return (
    <div className="flex flex-grow h-full">
      <div className="w-64 flex-shrink-0 overflow-auto border-r border-gray-300">
        <ActionMenu
          selectedContainerKeypath={selectedKeypath}
          keypath={keypath}
          onSelectContainer={handleSelectContainer}
        />
      </div>
      <div className="flex-grow flex flex-col">
        <div className="p-2 border-b border-gray-300">
          <h2 className="text-lg font-semibold">Container Editor</h2>
        </div>
        <div
          className="flex-grow relative"
          style={{ height: "calc(100vh - 350px)", overflow: "hidden" }}
        >
          <EditableContainerStructure
            keypath={`${keypath}/containerStructure`}
            onSelectContainer={handleSelectContainer}
            selectedKeypath={selectedKeypath}
          />
        </div>
      </div>
      <div className="w-64 flex-shrink-0 overflow-auto border-l border-gray-300">
        <ContainerPropertiesPanel selectedContainerKeypath={selectedKeypath} />
      </div>
    </div>
  );
};

export default ContainerModeEditor;
