import React from "react";
import { useParams, Route, Routes, Link } from "react-router-dom";
import {
  Badge,
  Box,
  Heading,
  Button,
  Flex,
  Divider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import RecordNameEditor from "@/bundles/DescriptorEditor/components/editors/RecordNameEditor";
import RecordDescriptionEditor from "@/bundles/DescriptorEditor/components/editors/RecordDescriptionEditor";
import CodeEditor from "@/bundles/DescriptorEditor/components/editors/CodeEditor";
import { layoutTemplates } from "@/bundles/DescriptorEditor/components/editors/ViewgraphEditor/LayoutModeEditor";

const ApplicationLayoutEditor = () => {
  const { layoutId } = useParams();
  const { getFragment, setFragment } = useAppDescriptorStore();
  const keypath = `/userInterface/applicationLayouts/${layoutId}`;
  const layout = getFragment(keypath) as any;

  if (!layout) {
    return <Text>Layout not found</Text>;
  }

  const setDefaultLayout = () => {
    const allLayoutsKeypath = "/userInterface/applicationLayouts";
    const allLayouts = (getFragment(allLayoutsKeypath) as any[]) || [];

    allLayouts.forEach((l) => {
      setFragment(`${allLayoutsKeypath}/id:${l.id}/isDefault`, false);
    });

    setFragment(`${keypath}/isDefault`, true);
  };

  return (
    <Box>
      <RecordNameEditor keypath={`${keypath}/name`} />
      <RecordDescriptionEditor keypath={`${keypath}/description`} />
      <Divider my={4} />
      <Tooltip
        label={
          layout.isDefault
            ? "Current layout is already default layout"
            : "Set as default layout"
        }
      >
        <Button onClick={setDefaultLayout} mb={4} isDisabled={layout.isDefault}>
          Set as default layout
        </Button>
      </Tooltip>
      <Heading size="md" mb={2}>
        ERB template
      </Heading>
      <CodeEditor keypath={`${keypath}/erbTemplate`} language="erb" />
    </Box>
  );
};

const ApplicationLayouts: React.FC = () => {
  const { getFragment } = useAppDescriptorStore();
  const allLayoutsKeypath = "/userInterface/applicationLayouts";
  const allLayouts = (getFragment(allLayoutsKeypath) as any[]) || [];

  return (
    <Flex height="100vh" overflow="hidden">
      <Box flex={1} p={4} overflowY="auto">
        <Heading size="lg" mb={4}>
          Available Layouts
        </Heading>
        <VStack spacing={4} align="stretch">
          {allLayouts.map((layout) => (
            <Box
              key={layout.id}
              p={4}
              borderWidth={1}
              borderRadius="md"
              borderColor="gray.200"
            >
              <Heading size="md">{layout.name}</Heading>
              <Text>{layout.description}</Text>
              <Link to={`/applicationLayouts/${layout.id}`}>
                <Button mt={2} colorScheme="blue">
                  Edit Layout
                </Button>
              </Link>
            </Box>
          ))}
          <Divider my={4} />
          <Heading size="lg" mb={4}>
            Layout Templates
          </Heading>
          {layoutTemplates.map((template) => (
            <Box
              key={template.value}
              p={4}
              borderWidth={1}
              borderRadius="md"
              borderColor="gray.200"
            >
              <Heading size="md">{template.name}</Heading>
              <Text>Template Value: {template.value}</Text>
            </Box>
          ))}
        </VStack>
        <Routes>
          <Route path=":layoutId" element={<ApplicationLayoutEditor />} />
        </Routes>
      </Box>
    </Flex>
  );
};

export default ApplicationLayouts;
