import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Text,
  useToast,
  Switch,
  HStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import pluralize from 'pluralize';

import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { PageGroup } from '@/bundles/DescriptorEditor/schemas/essentials/pagesSchema';
import { DefaultPageGroupGenerator } from '@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/DefaultPageGroupGenerator';

interface DataModel {
  id: string;
  name: string;
}

interface NewPageGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  dataModels: DataModel[];
}

const NewPageGroupModal: React.FC<NewPageGroupModalProps> = ({ isOpen, onClose, dataModels }) => {
  const [selectedDataModelId, setSelectedDataModelId] = useState<string>('');
  const [name, setName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [availableDataModels, setAvailableDataModels] = useState<DataModel[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [pageGroupType, setPageGroupType] = useState<'custom' | 'data'>('custom');

  const { getFragment, addRecordToCollectionFragment } = useAppDescriptorStore();

  useEffect(() => {
    if (isOpen) {
      setError(null);
      
      const pageGroups = getFragment('/essentials/pageGroups') as PageGroup[] || [];
      const existingDataGroups = pageGroups.filter(pageGroup => pageGroup.dataModelId);
      const availableModels = dataModels.filter(model => !existingDataGroups.map(group => group.dataModelId).includes(model.id));
      setAvailableDataModels(availableModels);
      
      if (availableModels.length > 0) {
        setPageGroupType('data');
        const firstAvailableModel = availableModels[0];
        setSelectedDataModelId(firstAvailableModel.id);
        setName(pluralize(firstAvailableModel.name));
      } else {
        setPageGroupType('custom');
        setSelectedDataModelId('');
        setName('Custom group #1');
      }
    }
  }, [isOpen, dataModels, getFragment]);

  const handlePageGroupTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.checked ? 'data' : 'custom';
    setPageGroupType(newType);
    if (newType === 'custom') {
      setSelectedDataModelId('');
      setName('Custom group #1');
    } else if (availableDataModels.length > 0) {
      const firstAvailableModel = availableDataModels[0];
      setSelectedDataModelId(firstAvailableModel.id);
      setName(pluralize(firstAvailableModel.name));
    }
  };

  const handleDataModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDataModelId = e.target.value;
    setSelectedDataModelId(newDataModelId);
    if (newDataModelId === '') {
      setName('Custom group #1');
    } else {
      const selectedModel = dataModels.find(model => model.id === newDataModelId);
      if (selectedModel) {
        setName(pluralize(selectedModel.name));
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      if (pageGroupType === 'custom') {
        await addRecordToCollectionFragment('/essentials/pageGroups', {
          id: `custom-${Date.now()}`,
          name: name,
          pages: []
        });
      } else {
        const selectedModel = dataModels.find(model => model.id === selectedDataModelId);
        if (selectedModel) {
          const generator = new DefaultPageGroupGenerator(selectedModel);
          const newPageGroup = generator.generatePageGroup(selectedDataModelId, selectedModel.name);
          await addRecordToCollectionFragment('/essentials/pageGroups', newPageGroup);
        }
      }
      onClose();
      toast({
        title: 'Success',
        description: 'Page group created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating page group:', error);
      setError('Failed to create page group. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to create page group. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isDataModelAlertShown = pageGroupType === 'data' && 
    (dataModels.length === 0 || availableDataModels.length === 0);

  const isSubmitDisabled = isSubmitting || !name.trim() || isDataModelAlertShown;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Page Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel>Page Group Type</FormLabel>
              <HStack>
                <Text>Custom</Text>
                <Switch
                  isChecked={pageGroupType === 'data'}
                  onChange={handlePageGroupTypeChange}
                />
                <Text>Data Model</Text>
              </HStack>
            </FormControl>
            {pageGroupType === 'data' && (
              <>
                {dataModels.length === 0 ? (
                  <Alert status="info">
                    <AlertIcon />
                    No data models added to app
                  </Alert>
                ) : availableDataModels.length === 0 ? (
                  <Alert status="info">
                    <AlertIcon />
                    All data models already have a data page group
                  </Alert>
                ) : (
                  <FormControl isRequired>
                    <FormLabel>Data Model</FormLabel>
                    <Select 
                      value={selectedDataModelId} 
                      onChange={handleDataModelChange}
                    >
                      {availableDataModels.map((model) => (
                        <option key={model.id} value={model.id}>{model.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
            {!isDataModelAlertShown && (
              <FormControl isRequired>
                <FormLabel>Group Name</FormLabel>
                <Input 
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  placeholder={pageGroupType === 'custom' ? "Custom group #1" : "Enter group name"}
                />
              </FormControl>
            )}
            {error && <Text color="red.500">{error}</Text>}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button 
            colorScheme="blue" 
            mr={3} 
            onClick={handleSubmit} 
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitting}
            loadingText="Adding Group"
          >
            Add Group
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPageGroupModal;