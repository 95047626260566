import React from "react";
import { Box, Flex, IconButton, Button, useToast } from "@chakra-ui/react";
import { HiDotsVertical, HiHome } from "react-icons/hi";
import { useParams, useLocation } from "react-router-dom";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import InlineEditableText from "../editors/InlineEditableText";
import { railsApiCall } from "../../utils/railsApiCall";
import {
  convertContainerToNodeTree,
  transformFormat,
} from "../editors/ViewgraphEditor/utils/convertAppDescriptors";
import { generateFlowgraph } from "@/bundles/FlowgraphEditor/utils/generateFlowgraph";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { generateConvertedDescriptor } from "../descriptors/utils/descriptorConverter";

const Header: React.FC = () => {
  const {
    projectName,
    setProjectName,
    getFragment,
    getPage,
    getModelByKeypath,
  } = useAppDescriptorStore();
  const { projectId } = useParams<{ projectId: string }>();
  const toast = useToast();
  const location = useLocation();

  const handleRename = async (newName: string) => {
    try {
      const response = await railsApiCall<{ name: string }>({
        method: "PATCH",
        endpoint: `/projects/${projectId}/rename`,
        body: { name: newName },
      });
      setProjectName(response.data.name);
    } catch (error) {
      console.error("Failed to rename project:", error);
    }
  };

  const handleSave = async () => {
    try {
      const transformed = generateConvertedDescriptor(
        getFragment,
        getPage,
        getModelByKeypath
      );

      if (!transformed) {
        toast({
          title: "Error",
          description: "No page group found",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }

      await railsApiCall({
        method: "POST",
        endpoint: `/projects/${projectId}/save_converted_descriptor`,
        body: { converted_descriptor: transformed },
      });

      toast({
        title: "Success",
        description: "Converted descriptor saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return true;
    } catch (error) {
      console.error("Failed to save converted descriptor:", error);
      toast({
        title: "Error",
        description: "Failed to save converted descriptor",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleExport = async () => {
    const saveResult = await handleSave();
    if (saveResult) {
      try {
        const response = await railsApiCall({
          method: "GET",
          endpoint: `/projects/${projectId}/export`,
        });

        if (response.data) {
          window.open(
            `https://kos-runtime-839881b6cd73.herokuapp.com/kasey_os/set_active_app/${projectId}`,
            "_blank"
          );
        } else {
          toast({
            title: "Error",
            description: "Failed to verify saved data",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Failed to verify export data:", error);
        toast({
          title: "Error",
          description: "Failed to verify export data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex
      align="center"
      justify="space-between"
      borderBottom="1px solid"
      borderColor="gray.800"
      p={4}
      h="64px"
    >
      <Box>
        <IconButton
          as="a"
          href="/projects"
          aria-label="Home"
          icon={<HiHome />}
          variant="ghost"
        />
      </Box>
      <Box textAlign="center">
        <InlineEditableText
          value={projectName}
          onSave={handleRename}
          fontSize="xl"
          fontWeight="bold"
        />
      </Box>
      <Flex gap={2}>
        <Button size="sm" variant="outline" onClick={handleSave}>
          Save
        </Button>
        <IconButton
          size="sm"
          variant="outline"
          onClick={handleExport}
          aria-label="Export UI"
          icon={<ExternalLinkIcon />}
        />
        <IconButton
          aria-label="Settings"
          icon={<HiDotsVertical />}
          variant="ghost"
        />
      </Flex>
    </Flex>
  );
};

export default Header;
