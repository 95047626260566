import React from "react";
import { Box, Text, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconChevronUp,
  IconChevronDown,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";

interface StaticValueNodeProps {
  node: ConditionalNode;
  onSelectField: (
    nodeId: string,
    field: "Number1" | "Number2" | "name"
  ) => void;
  onDeleteNode: (nodeId: string) => void;
  isSelected: boolean;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
}

export function StaticValueNode({
  node,
  onSelectField,
  onDeleteNode,
  isSelected,
  onReorder,
  isFirst,
  isLast,
}: StaticValueNodeProps) {
  const iconColor = "rgb(138, 180, 248)";

  const handleClick = () => {
    onSelectField(node.id, "Number1");
  };

  const displayValue = node.number1?.startsWith("$")
    ? `Variable: ${node.number1.substring(1)}`
    : node.number1 || "(x)";

  return (
    <Box
      borderWidth={1}
      borderColor={iconColor}
      borderRadius="md"
      bg="#202124"
      width="350px"
      overflow="hidden"
      position="relative"
      boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
      _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
    >
      <HStack p={3} bg={`${iconColor}20`} justifyContent="space-between">
        <HStack>
          <IconButton
            icon={<IconMinus size={16} />}
            aria-label="Collapse"
            size="sm"
            variant="ghost"
            color={colors.grayDark.gray12}
          />
          <Text fontWeight="medium" color="white">
            Static Value
          </Text>
        </HStack>
        <HStack>
          {!isFirst && (
            <Tooltip label="Move Up" aria-label="Move Up Tooltip">
              <IconButton
                icon={<IconChevronUp size={16} />}
                aria-label="Move Up"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("up")}
              />
            </Tooltip>
          )}
          {!isLast && (
            <Tooltip label="Move Down" aria-label="Move Down Tooltip">
              <IconButton
                icon={<IconChevronDown size={16} />}
                aria-label="Move Down"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("down")}
              />
            </Tooltip>
          )}
          <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
            <IconButton
              icon={<IconTrash size={16} />}
              aria-label="Delete Node"
              size="sm"
              variant="ghost"
              color={colors.red.red6}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteNode(node.id);
              }}
              _hover={{ bg: colors.red.red3 }}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <Box
        p={3}
        bg="#303134"
        cursor="pointer"
        onClick={handleClick}
        _hover={{ bg: "#3c4043" }}
      >
        <HStack justifyContent="space-between">
          <Text color="white" fontWeight="medium">
            {displayValue}
          </Text>
          <Text color="gray.900">String Value</Text>
        </HStack>
      </Box>
    </Box>
  );
}
