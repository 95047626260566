import { TypedAppDescriptor } from "../schemas/appDescriptorSchema";
import { v4 as uuidv4 } from "uuid";
import { dataModels } from "./dataModels";
import { pages, pageGroups } from "./pages";
import { apiEndpoints, apiNamespaces } from "./apiDefinitions";
import { applicationLayouts } from "./applicationLayouts";
import { componentBlueprints } from "./componentBlueprints";
import { navigationMenus } from "./navigationMenus";

export const seedData: TypedAppDescriptor = {
  name: "Car Index App",
  essentials: {
    dataModels: [
      {
        id: "5",
        name: "Car",
        description: "A model to store cars",
        fields: [
          {
            id: "1",
            name: "make",
            dataType: "_types.String",
            description: "The make of the car",
          },
          {
            id: "2",
            name: "model",
            dataType: "_types.String",
            description: "The model of the car",
          },
        ],
      },
    ],
    pageGroups: [
      {
        id: "79e21bee-d53a-4879-b5b9-afba448d2fee",
        name: "Car Pages",
        description: "Pages for car management",
        basePath: "/cars",
        dataModelId: "5",
        modelName: "Car",
        pages: [
          {
            id: "ba660f92-fac7-4739-b34b-b30938de5c4a",
            name: "Car Index",
            pageType: "index",
            dataModelId: "5",
            description: "Render a page to list all car records.",
            relativePath: "/",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [],
                  isScrollable: true,
                  subcontainers: [
                    {
                      id: "UI",
                      size: { unit: "fr", value: 1 },
                      components: [
                        {
                          id: uuidv4(),
                          blueprintName: "Button",
                          propertiesBindings: {
                            text: {
                              directiveType: "literalValue",
                              config: {
                                type: "String",
                                value: "Add Car",
                              },
                            },
                          },
                          reactions: {},
                          layout: {},
                        },
                        {
                          id: "su4xc1hme",
                          blueprintName: "CollectionTable",
                          propertiesBindings: {
                            associatedRecord: {
                              directiveType: "getPageVariable",
                              config: {
                                type: "_types.ActiveRecord",
                                variableName: "jk",
                              },
                            },
                            title: {
                              directiveType: "literalValue",
                              config: {
                                type: "String",
                                value: null,
                              },
                            },
                            showNewButton: {
                              directiveType: "literalValue",
                              config: {
                                type: "Boolean",
                                value: null,
                              },
                            },
                          },
                          reactions: {},
                          layout: {},
                        },
                      ],
                      isScrollable: true,
                      subcontainers: [],
                      isLayoutApplied: true,
                      layoutDirection: "vertical",
                    },
                  ],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [
                  {
                    id: uuidv4(),
                    name: "Model Method",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      modelName: "Car",
                      methodType: "RetrieveAllRecords",
                      returnVariable: "cars",
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                  {
                    id: uuidv4(),
                    name: "Service Call",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      serviceName: "KaseyOS.Controller",
                      methodName: "RenderCurrentPage",
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                ],
                $variables: [],
              },
              parameters: [],
            },
          },
          {
            id: "show-page-id",
            name: "Car Show",
            pageType: "show",
            dataModelId: "5",
            description: "Show details of a car record.",
            relativePath: "/:id",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [],
                  isScrollable: true,
                  subcontainers: [
                    {
                      id: "UI",
                      size: { unit: "fr", value: 1 },
                      components: [
                        {
                          id: uuidv4(),
                          blueprintName: "DataDisplay_DataCard",
                          propertiesBindings: {
                            title: {
                              directiveType: "literalValue",
                              config: {
                                type: "String",
                                value: "Car details",
                              },
                            },
                            fields: {
                              directiveType: "literalValue",
                              config: {
                                type: "Array",
                                value: [
                                  { label: "Make", value: "Tesla" },
                                  { label: "Model", value: "Model 3" },
                                ],
                              },
                            },
                          },
                          reactions: {},
                          layout: {},
                        },
                      ],
                      isScrollable: true,
                      subcontainers: [],
                      isLayoutApplied: true,
                      layoutDirection: "vertical",
                    },
                  ],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [
                  {
                    id: uuidv4(),
                    name: "Model Method",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      modelName: "Car",
                      methodType: "RetrieveRecord",
                      returnVariable: "car",
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                ],
                $variables: [],
              },
              parameters: [],
            },
          },
          {
            id: "new-page-id",
            name: "Car New",
            pageType: "new",
            dataModelId: "5",
            description: "Create a new car record.",
            relativePath: "/new",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [],
                  isScrollable: true,
                  subcontainers: [
                    {
                      id: "UI",
                      size: { unit: "fr", value: 1 },
                      components: [
                        {
                          id: uuidv4(),
                          blueprintName: "Form",
                          propertiesBindings: {
                            schema: {
                              directiveType: "literalValue",
                              config: {
                                type: "Object",
                                value: {
                                  type: "Record",
                                  dataModel: "Car",
                                  includedFields: ["make", "model"],
                                },
                              },
                            },
                          },
                          reactions: {
                            submit: {
                              flowgraph: {
                                sequence: [
                                  {
                                    id: uuidv4(),
                                    name: "Model Method",
                                    parameters: {
                                      modelName: "Car",
                                      methodType: "CreateRecord",
                                      returnVariable: "newCar",
                                    },
                                    childrenYes: [],
                                    childrenNo: [],
                                    functionCall: null,
                                    comparisonOperator: "Greater than",
                                  },
                                  {
                                    id: uuidv4(),
                                    name: "Navigation",
                                    parameters: {
                                      page: "CarsIndex",
                                    },
                                    childrenYes: [],
                                    childrenNo: [],
                                    functionCall:
                                      "KaseyOS.Router.NavigateToPage",
                                    comparisonOperator: "Greater than",
                                  },
                                ],
                              },
                            },
                          },
                          layout: {},
                          children: [
                            {
                              id: uuidv4(),
                              blueprintName: "FormSection",
                              propertiesBindings: {
                                label: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "Car Details",
                                  },
                                },
                              },
                              reactions: {},
                              layout: {},
                              children: [
                                {
                                  id: uuidv4(),
                                  blueprintName: "SingleLineTextInput",
                                  propertiesBindings: {
                                    keypath: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "make",
                                      },
                                    },
                                    label: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "Make",
                                      },
                                    },
                                    placeholder: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "Enter car make...",
                                      },
                                    },
                                  },
                                  reactions: {},
                                  layout: {},
                                },
                                {
                                  id: uuidv4(),
                                  blueprintName: "SingleLineTextInput",
                                  propertiesBindings: {
                                    keypath: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "model",
                                      },
                                    },
                                    label: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "Model",
                                      },
                                    },
                                    placeholder: {
                                      directiveType: "literalValue",
                                      config: {
                                        type: "String",
                                        value: "Enter car model...",
                                      },
                                    },
                                  },
                                  reactions: {},
                                  layout: {},
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      isScrollable: true,
                      subcontainers: [],
                      isLayoutApplied: true,
                      layoutDirection: "vertical",
                    },
                  ],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [
                  {
                    id: uuidv4(),
                    name: "Model Method",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      modelName: "Car",
                      methodType: "CreateRecord",
                      make: {
                        $bindTo: "$payload/formData/make",
                      },
                      model: {
                        $bindTo: "$payload/formData/model",
                      },
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                  {
                    id: uuidv4(),
                    name: "Navigation",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      page: "CarsIndex",
                    },
                    comparisonOperator: "",
                    functionCall: "KaseyOS.Router.NavigateToPage",
                    number1: null,
                    number2: null,
                  },
                ],
                $variables: [],
              },
              parameters: [],
            },
          },
        ],
      },
    ],
    services: [],
    apiEndpoints: [],
    apiNamespaces: [],
    dataTypes: [],
  },
  userInterface: {
    applicationLayouts: [
      {
        id: uuidv4(),
        name: "Default Layout",
        description: "The default application layout",
        erbTemplate: `<!DOCTYPE html>
<html>
  <head>
    <title><%= @page[:title] %></title>
    <%= csrf_meta_tags %>
    <%= csp_meta_tag %>
    <%= stylesheet_link_tag 'application', media: 'all', 'data-turbolinks-track': 'reload' %>
    <%= javascript_pack_tag 'application', 'data-turbolinks-track': 'reload' %>
    <script src="https://cdn.tailwindcss.com"></script>
  </head>
  <body class="h-full w-full bg-gray-100">
    <div class="h-full w-full max-w-[1024px] mx-auto pt-10">
      <%= content %>
    </div>
  </body>
</html>`,
        isDefault: true,
      },
    ],
    componentBlueprints: [],
  },
};
