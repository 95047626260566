export const generateFlowgraph = (conditionalNodes: any[]) => {
  const flowgraph = {
    sequence: [],
    context: {
      $variables: {},
      $constants: {},
    },
  };

  const processNode = (node: {
    name: string;
    id: any;
    parameters: {
      id: string | number;
      value: any;
      mode?: string;
      isDataVariable?: boolean;
      modelName?: string;
      recordLimit?: number;
      returnVariable?: string;
      methodType?: string;
      make?: string;
      model?: string;
      recordId?: string;
    };
    childrenYes: any[];
    childrenNo: any[];
    functionCall?: string;
    number1?: string | null;
    condition?: any;
  }) => {
    if (node.name === "Variable") {
      // Temporarily exclude Set mode TODO: Fix this
      if (node.parameters.mode !== "Set") {
        const variableNode = {
          $id: node.id,
          $call: "Variable.Get",
          $arguments: {
            id: node.parameters.id,
          },
        };
        flowgraph.sequence.push(variableNode);
      }

      if (node.parameters.mode === "Set") {
        if (!flowgraph.context.$variables) {
          flowgraph.context.$variables = {};
        }
        flowgraph.context.$variables[node.parameters.id] = {
          isDataVariable: node.parameters.isDataVariable || false,
          schema: {
            type: node.parameters.isDataVariable ? "DataModel" : "String",
            ...(node.parameters.isDataVariable && {
              modelName: node.parameters.value,
            }),
          },
          value: node.parameters.value || "",
        };
      }
    } else if (node.name === "Constant") {
      const constantNode = {
        $id: node.id,
        $call: "Constant.Get",
        $arguments: {
          id: node.parameters.id,
        },
      };
      flowgraph.sequence.push(constantNode);

      flowgraph.context.$constants[node.parameters.id] = {
        schema: { type: "String" },
        value: "Hello",
      };
    } else if (node.name === "Formula function") {
      const functionNode = {
        $id: node.id,
        $call: "FunctionCall.Execute",
        $arguments: {
          functionCall: {
            $call: node.functionCall,
            $deferUntilExecution: true,
            $arguments: {},
          },
        },
      };

      for (const [paramName, paramValue] of Object.entries(node.parameters)) {
        if (
          typeof paramValue === "object" &&
          paramValue !== null &&
          "$call" in paramValue
        ) {
          functionNode.$arguments.functionCall.$arguments[paramName] =
            processNestedFunctionCall(paramValue);
        } else {
          functionNode.$arguments.functionCall.$arguments[paramName] =
            paramValue;
        }
      }

      flowgraph.sequence.push(functionNode);
    } else if (node.name === "Static Value") {
      const staticValueNode = {
        $id: node.id,
        $call: "Utility.Identity",
        $arguments: {
          value: node.number1 || "",
        },
      };

      if (node.number1 && node.number1.startsWith("$")) {
        staticValueNode.$arguments.value = {
          $call: "Variable.Get",
          $arguments: {
            id: node.number1.substring(1),
          },
        };
      }

      flowgraph.sequence.push(staticValueNode);
    } else if (node.name === "Conditional Statement") {
      const conditionalNode = {
        $id: node.id,
        $call: "Control.If",
        $arguments: {
          condition: true,
          then: {
            sequence: [],
          },
          else: {
            sequence: [],
          },
        },
      };

      if (node.childrenYes.length > 0) {
        const thenFlowgraph = generateFlowgraph(node.childrenYes);
        conditionalNode.$arguments.then.sequence = thenFlowgraph.sequence;
      }

      if (node.childrenNo.length > 0) {
        const elseFlowgraph = generateFlowgraph(node.childrenNo);
        conditionalNode.$arguments.else.sequence = elseFlowgraph.sequence;
      }

      flowgraph.sequence.push(conditionalNode);
    } else if (node.name === "Model Method") {
      if (node.parameters.methodType === "RetrieveAllRecords") {
        const modelMethodNode = {
          $id: node.id,
          $call: "KaseyOS.DataModel.RetrieveAllRecords",
          $arguments: {
            dataModel: node.parameters.modelName,
            limit: node.parameters.recordLimit,
          },
          $assignTo: `$data/${node.parameters.returnVariable}`,
        };
        flowgraph.sequence.push(modelMethodNode);
      } else if (node.parameters.methodType === "RetrieveRecord") {
        const modelMethodNode = {
          $id: node.id,
          $call: "KaseyOS.DataModel.RetrieveRecord",
          $arguments: {
            dataModel: node.parameters.modelName,
            id: {
              $call: "Context.Get",
              $arguments: {
                keypath: node.parameters.recordId,
              },
            },
          },
          $assignTo: `$data/${node.parameters.returnVariable}`,
        };
        flowgraph.sequence.push(modelMethodNode);
      } else if (node.parameters.methodType === "CreateRecord") {
        const createRecordNode = {
          $id: node.id,
          $call: "KaseyOS.DataModel.CreateRecord",
          $arguments: {
            dataModel: node.parameters.modelName,
            record: {
              make: node.parameters.make,
              model: node.parameters.model,
            },
          },
        };
        flowgraph.sequence.push(createRecordNode);
      }
    }

    if (node.name !== "Conditional Statement") {
      node.childrenYes.forEach(processNode);
      node.childrenNo.forEach(processNode);
    }
  };

  const processCondition = (condition: any) => {
    // Note: This function should process the condition based on its structure
    // For now, we'll assume it's a simple comparison or a function call
    if (typeof condition === "object" && condition !== null) {
      if ("$call" in condition) {
        return processNestedFunctionCall(condition);
      } else {
        // Assume it's a comparison
        return {
          $call: "Comparison.Execute",
          $arguments: condition,
        };
      }
    }
    return condition;
  };

  const processNestedFunctionCall = (functionCall: any) => {
    const nestedCall = {
      $call: functionCall.$call,
      $arguments: {},
    };

    for (const [paramName, paramValue] of Object.entries(
      functionCall.$arguments
    )) {
      if (Array.isArray(paramValue)) {
        nestedCall.$arguments[paramName] = paramValue.map((item) =>
          typeof item === "object" && item !== null && "$call" in item
            ? processNestedFunctionCall(item)
            : item
        );
      } else if (
        typeof paramValue === "object" &&
        paramValue !== null &&
        "$call" in paramValue
      ) {
        nestedCall.$arguments[paramName] =
          processNestedFunctionCall(paramValue);
      } else {
        nestedCall.$arguments[paramName] = paramValue;
      }
    }

    return nestedCall;
  };

  conditionalNodes.forEach(processNode);

  flowgraph.sequence.push({
    $call: "KaseyOS.Controller.RenderCurrentPage",
  });

  return flowgraph;
};
