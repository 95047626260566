import { generateFlowgraph } from "@/bundles/FlowgraphEditor/utils/generateFlowgraph";
import { v4 as uuidv4 } from "uuid";

interface OldFormat {
  id: string;
  name: string;
  pageType: string;
  dataModelId: string;
  description: string;
  relativePath: string;
  userInterface: {
    viewgraph: {
      enabled: boolean;
      containerStructure: {
        subcontainers: Array<{
          components: Array<{
            propertiesBindings: {
              title: {
                config: {
                  value: string;
                };
              };
              tableConfig: {
                config: {
                  value: {
                    columns: {
                      config: {
                        value: Array<{
                          title: string;
                          fieldKey: string;
                        }>;
                      };
                    };
                  };
                };
              };
            };
          }>;
        }>;
      };
    };
  };
  initializationLogic: {
    flowgraph: {
      sequence: any[];
    };
  };
  pageGroup: {
    id: string;
    name: string;
    basePath: string;
    dataModelId: string;
    modelName: string;
  };
}

interface NewFormat {
  id: string;
  description: string;
  dataModels: Array<{
    name: string;
    description: string;
    fields: Array<{
      name: string;
      databaseType: string;
    }>;
  }>;
  pageGroups: Array<{
    name: string;
    description: string;
    dataModel: string;
    basePath: string;
    pages: Array<{
      name: string;
      relativePath: string;
      pageType: string;
      dataVariables: Array<{
        name: string;
        schema: {
          type: string;
          dataModel: string;
        };
        description: string;
      }>;
      controllerAction: {
        name: string;
        flowgraph: {
          sequence: any[];
        };
      };
      viewgraph: {
        nodeTree: any;
      };
    }>;
  }>;
  installedModules: Array<{ name: string }>;
}

export function convertContainerToNodeTree(
  container: any,
  dataModelFields: any[] = []
): any {
  const node = {
    $element: "div",
    $styleClasses: "p-4 w-full max-w-screen-lg mx-auto",
    $children: [
      {
        $component: "Typography_PageHeader",
        $props: {
          text: "Cars",
        },
      },
    ],
  };

  if (container.isLayoutApplied) {
    node.$styleClasses += ` flex ${
      container.layoutDirection === "vertical" ? "flex-col" : "flex-row"
    }`;
  }

  if (container.size) {
    if (container.size.unit === "fr") {
      node.$styleClasses += ` flex-${container.size.value}`;
    }
  }

  if (container.components) {
    const componentNodes = container.components
      .map((component: any) => {
        if (component.blueprintName === "CollectionTable") {
          return {
            $component: "Table_Simple",
            $props: {
              data: {
                $bindTo: `$data/cars`,
              },
              columns: [
                { dataKey: "make", label: "Make" },
                { dataKey: "model", label: "Model" },
              ],
            },
            $eventHandlers: [
              {
                $on: "rowClick",
                $execute: {
                  $flowgraph: {
                    sequence: [
                      {
                        $call: "KaseyOS.Router.NavigateToPage",
                        $arguments: {
                          page: "CarsShow",
                          params: {
                            id: {
                              $call: "Context.Get",
                              $arguments: {
                                keypath: "$payload/id",
                              },
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            ],
          };
        } else if (component.blueprintName === "Button") {
          return {
            $component: "Button_Primary",
            $props: {
              text: component.propertiesBindings.text.config.value,
            },
            $eventHandlers: [
              {
                $on: "click",
                $execute: {
                  $flowgraph: {
                    sequence: [
                      {
                        $call: "KaseyOS.Router.NavigateToPage",
                        $arguments: {
                          page: "CarsNew",
                        },
                      },
                    ],
                  },
                },
              },
            ],
          };
        } else if (component.blueprintName === "DataDisplay_DataCard") {
          return {
            $component: "DataDisplay_DataCard",
            $props: {
              title: "Car details",
              fields: [
                {
                  label: "Make",
                  value: { $bindTo: "$data/car/make" },
                },
                {
                  label: "Model",
                  value: { $bindTo: "$data/car/model" },
                },
              ],
            },
          };
        } else if (component.blueprintName === "Form_Card") {
          return {
            $component: "Form_Card",
            $props: {
              schema: {
                type: "Record",
                dataModel: "Car",
                includedFields: ["make", "model"],
              },
              submitButtonText: "Create new car",
            },
            $eventHandlers: [
              {
                $on: "submit",
                $execute: {
                  $flowgraph: {
                    sequence: [
                      {
                        $call: "Utility.Log",
                        $arguments: {
                          message: {
                            $call: "Context.Get",
                            $arguments: { keypath: "$payload" },
                          },
                        },
                      },
                      {
                        $call: "KaseyOS.DataService.Call",
                        $arguments: {
                          serviceName: "Cars",
                          methodName: "create",
                          methodArguments: {
                            newRecord: {
                              $call: "Context.Get",
                              $arguments: {
                                keypath: "$payload/formData",
                              },
                            },
                          },
                        },
                      },
                      {
                        $call: "KaseyOS.Router.NavigateToPage",
                        $arguments: { page: "CarsIndex" },
                      },
                    ],
                  },
                },
              },
            ],
            $slots: {
              formContent: {
                $children: [
                  {
                    $component: "Form_Section",
                    $props: { label: "Car Details" },
                    $slots: {
                      sectionContent: {
                        $children: [
                          {
                            $element: "div",
                            $styleClasses: "flex flex-col gap-4",
                            $children: [
                              {
                                $component: "Form_Field_SingleLineTextInput",
                                $props: {
                                  keypath: "make",
                                  label: "Make",
                                  placeholder: "Enter car make...",
                                },
                              },
                              {
                                $component: "Form_Field_SingleLineTextInput",
                                $props: {
                                  keypath: "model",
                                  label: "Model",
                                  placeholder: "Enter car model...",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          };
        }
        return null;
      })
      .filter(Boolean);

    node.$children.push(...componentNodes);
  }

  return node;
}

function convertFlowgraphNode(flowgraph: any): any {
  if (!flowgraph || !Array.isArray(flowgraph)) return { sequence: [] };

  const nodes = flowgraph.map((node: any) => ({
    id: node.id || uuidv4(),
    name:
      node.directiveType === "declarePageVariable"
        ? "Variable"
        : node.directiveType === "modelMethod"
        ? "Model Method"
        : node.directiveType,
    parameters: {
      id: node.config?.name,
      value: node.config?.initialValue,
      modelName: node.config?.modelName,
      recordLimit: node.config?.recordLimit,
      returnVariable: node.config?.name,
      methodType: node.config?.methodType,
      make: node.config?.make,
      model: node.config?.model,
    },
    childrenYes: [],
    childrenNo: [],
  }));

  return generateFlowgraph(nodes);
}

// IMPORTANT: When this is updated, make sure to update the Ruby API code as well
export function transformFormat(oldFormat: OldFormat): NewFormat {
  const modelName = oldFormat.pageGroup.modelName || oldFormat.name;
  const cleanModelName = modelName.replace(/Index$/, "");

  const dataModel = {
    name: cleanModelName,
    description: `A ${cleanModelName.toLowerCase()}`,
    fields: [
      { name: "make", databaseType: "string" },
      { name: "model", databaseType: "string" },
    ],
  };

  const dataService = {
    name: `${cleanModelName}s`,
    description: `A data service for the ${cleanModelName} model`,
    methods: [
      {
        name: "create",
        description: `Create a new ${cleanModelName.toLowerCase()}`,
        parameters: [
          {
            name: "newRecord",
            description: `The new ${cleanModelName.toLowerCase()} record to create`,
            schema: { type: "Record", dataModel: cleanModelName },
          },
        ],
        flowgraph: {
          sequence: [
            {
              $call: "KaseyOS.DataModel.CreateRecord",
              $arguments: {
                dataModel: cleanModelName,
                newRecord: {
                  $call: "Context.Get",
                  $arguments: { keypath: "$arguments/newRecord" },
                },
              },
            },
          ],
        },
      },
    ],
  };

  const transformed: NewFormat = {
    id: cleanModelName,
    description: `A simple app to manage ${cleanModelName.toLowerCase()}s`,
    dataModels: [dataModel],
    dataServices: [dataService],
    pageGroups: [
      {
        name: cleanModelName,
        description: `A page group for the ${cleanModelName} model`,
        dataModel: cleanModelName,
        basePath: `/${cleanModelName.toLowerCase()}s`,
        pages: [
          {
            name: `${cleanModelName}sIndex`,
            relativePath: "/",
            pageType: "index",
            dataVariables: [
              {
                name: "cars",
                schema: { type: "Collection", dataModel: cleanModelName },
                description: "The collection to display",
              },
            ],
            controllerAction: {
              name: "index",
              flowgraph: {
                sequence: [
                  {
                    $call: "KaseyOS.DataModel.RetrieveAllRecords",
                    $arguments: { dataModel: cleanModelName },
                    $assignTo: "$data/cars",
                  },
                  {
                    $call: "KaseyOS.Controller.RenderCurrentPage",
                  },
                ],
              },
            },
            viewgraph: {
              nodeTree: convertContainerToNodeTree(
                oldFormat.userInterface.viewgraph.containerStructure,
                dataModel.fields
              ),
            },
          },
        ],
      },
    ],
  };

  return transformed;
}

function findTableColumns(
  container: any
): Array<{ fieldKey: string; title: string }> {
  let columns: Array<{ fieldKey: string; title: string }> = [];

  if (container.components) {
    for (const component of container.components) {
      if (
        component.propertiesBindings?.tableConfig?.config?.value?.columns
          ?.config?.value
      ) {
        return component.propertiesBindings.tableConfig.config.value.columns
          .config.value;
      }
    }
  }

  if (container.subcontainers) {
    for (const subcontainer of container.subcontainers) {
      const subColumns = findTableColumns(subcontainer);
      if (subColumns.length > 0) {
        columns = subColumns;
        break;
      }
    }
  }

  return columns;
}

function convertComponentToNode(component: any, dataModelFields: any[]): any {
  const node: any = {
    $component: component.blueprintName,
    $props: {},
  };

  // Convert properties bindings
  if (component.propertiesBindings) {
    Object.entries(component.propertiesBindings).forEach(
      ([key, binding]: [string, any]) => {
        if (binding.directiveType === "literalValue") {
          node.$props[key] = binding.config.value;
        }
      }
    );
  }

  // Convert children if they exist
  if (component.children?.length > 0) {
    if (component.blueprintName === "Form_Section") {
      node.$slots = {
        sectionContent: {
          $children: component.children.map((child: any) =>
            convertComponentToNode(child, dataModelFields)
          ),
        },
      };
    } else {
      node.$children = component.children.map((child: any) =>
        convertComponentToNode(child, dataModelFields)
      );
    }
  }

  return node;
}
