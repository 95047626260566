const functions = [
  {
    name: "Math.Add",
    description: "Adds two numbers together",
    parameters: [
      {
        name: "a",
        description: "The first number to add",
        schema: {
          type: "Number",
        },
      },
      {
        name: "b",
        description: "The second number to add",
        schema: {
          type: "Number",
        },
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Add together",
        },
        {
          type: "BindingEditor",
          forArgument: "a",
        },
        {
          type: "Text",
          text: "and",
        },
        {
          type: "BindingEditor",
          forArgument: "b",
        },
      ],
    },
    implementation: {
      js: "const { a, b } = args;\nreturn a + b;\n",
      ruby: "a = args[:a] \nb = args[:b]\nreturn a + b\n",
    },
    returnValue: {
      schema: {
        type: "Number",
      },
      description: "The sum of the numbers",
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Add two numbers",
          arguments: {
            a: 2,
            b: 3,
          },
          expectReturnValue: [
            {
              toEqual: 5,
            },
          ],
        },
        {
          description: "Add two negative numbers",
          arguments: {
            a: -2,
            b: -3,
          },
          expectReturnValue: [
            {
              toEqual: -5,
            },
          ],
        },
        {
          description: "Add a number and zero",
          arguments: {
            a: 5,
            b: 0,
          },
          expectReturnValue: [
            {
              toEqual: 5,
            },
          ],
        },
      ],
    },
  },
  {
    name: "String.Capitalize",
    description:
      "Capitalize the first character of a string and lowercase the rest. If the string is empty, already capitalized, or starts with a non-alphabetic character, it returns the string as is.",
    parameters: [
      {
        name: "string",
        schema: {
          type: "String",
          required: true,
        },
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Capitalize the text",
        },
        {
          type: "BindingEditor",
          forArgument: "string",
        },
      ],
    },
    implementation: {
      js: "const { string } = args;\nif (string.length === 0) {\n  return string;\n}\nreturn string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();\n",
      ruby: "string = args[:string]\nreturn string if string.empty?\nreturn string.capitalize\n",
    },
    returnValue: {
      schema: {
        type: "String",
      },
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Capitalize a lowercase string",
          arguments: {
            string: "hello world",
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Capitalize an already capitalized string",
          arguments: {
            string: "Hello World",
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Capitalize a single character",
          arguments: {
            string: "a",
          },
          expectReturnValue: [
            {
              toEqual: "A",
            },
          ],
        },
        {
          description: "Handle empty string",
          arguments: {
            string: "",
          },
          expectReturnValue: [
            {
              toEqual: "",
            },
          ],
        },
        {
          description: "Handle non-alphabetic first character",
          arguments: {
            string: "123abc",
          },
          expectReturnValue: [
            {
              toEqual: "123abc",
            },
          ],
        },
        {
          description: "Handle string with only whitespace",
          arguments: {
            string: "   ",
          },
          expectReturnValue: [
            {
              toEqual: "   ",
            },
          ],
        },
        {
          description: "Handle string with leading whitespace",
          arguments: {
            string: "   hello",
          },
          expectReturnValue: [
            {
              toEqual: "   hello",
            },
          ],
        },
        {
          description: "Handle string with trailing whitespace",
          arguments: {
            string: "hello   ",
          },
          expectReturnValue: [
            {
              toEqual: "Hello   ",
            },
          ],
        },
        {
          description:
            "Handle string with multiple leading and trailing whitespace",
          arguments: {
            string: "   hello   ",
          },
          expectReturnValue: [
            {
              toEqual: "   hello   ",
            },
          ],
        },
        {
          description: "Handle all capital letters string",
          arguments: {
            string: "HELLO WORLD",
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Handle all lowercase string",
          arguments: {
            string: "hello world",
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Handle string with mixed case",
          arguments: {
            string: "HeLLo WoRLd",
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Handle string with multiple words",
          arguments: {
            string: "this is a test",
          },
          expectReturnValue: [
            {
              toEqual: "This is a test",
            },
          ],
        },
        {
          description:
            "Handle string with multiple words and leading/trailing whitespace",
          arguments: {
            string: "   this IS a test   ",
          },
          expectReturnValue: [
            {
              toEqual: "   this is a test   ",
            },
          ],
        },
        {
          description:
            "Handle string with multiple words and non-alphabetic characters",
          arguments: {
            string: "123abc!@#",
          },
          expectReturnValue: [
            {
              toEqual: "123abc!@#",
            },
          ],
        },
      ],
    },
  },
  {
    name: "String.Concatenate",
    description: "Concatenates an array of strings into a single string.",
    parameters: [
      {
        name: "strings",
        schema: {
          type: "Array",
          items: {
            type: "String",
          },
          required: true,
        },
      },
      {
        name: "delimiter",
        schema: {
          type: "String",
          default: "",
          required: false,
        },
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Concatenate together strings from the array",
        },
        {
          type: "BindingEditor",
          forArgument: "strings",
        },
        {
          type: "Text",
          text: "using delimiter",
        },
        {
          type: "BindingEditor",
          forArgument: "delimiter",
        },
      ],
    },
    implementation: {
      js: 'const { strings, delimiter = "" } = args;\nreturn strings.join(delimiter);\n',
      ruby: 'strings = args[:strings]\ndelimiter = args[:delimiter] || ""\nreturn strings.join(delimiter)\n',
    },
    returnValue: {
      schema: {
        type: "String",
      },
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Concatenate two strings",
          arguments: {
            strings: ["Hello", "World"],
          },
          expectReturnValue: [
            {
              toEqual: "HelloWorld",
            },
          ],
        },
        {
          description: "Concatenate multiple strings with custom delimiter",
          arguments: {
            strings: ["One", "Two", "Three"],
            delimiter: ", ",
          },
          expectReturnValue: [
            {
              toEqual: "One, Two, Three",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Logical.Not",
    description: "Performs a logical NOT operation on the given boolean value",
    parameters: [
      {
        name: "value",
        schema: {
          type: "Boolean",
          required: true,
        },
        description: "The boolean value to negate",
      },
    ],
    editorTemplate: {
      type: "Custom",
    },
    implementation: {
      ruby: "!args[:value]\n",
    },
    returnValue: {
      schema: {
        type: "Boolean",
      },
      description: "The negated boolean value",
    },
    characteristics: {
      readsContext: false,
      readsDatabase: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Negate true",
          arguments: {
            value: true,
          },
          expectReturnValue: [
            {
              toEqual: false,
            },
          ],
        },
        {
          description: "Negate false",
          arguments: {
            value: false,
          },
          expectReturnValue: [
            {
              toEqual: true,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.AppendItem",
    description: "Adds a new item to the end of an array.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The array to which the item will be added.",
      },
      {
        name: "value",
        schema: {
          type: "Any",
          required: true,
        },
        description: "The item to add to the array.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      ruby: "array = args[:array]\nvalue = args[:value]\narray.push(value)\narray\n",
      js: "const array = args.array;\nconst value = args.value;\narray.push(value);\nreturn array;\n",
    },
    returnValue: {
      schema: {
        type: "Array",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: false,
      isDeterministic: false,
      hasSideEffects: true,
    },
    tests: {
      cases: [
        {
          description: "Push an item to an array",
          arguments: {
            array: [1, 2, 3],
            value: 4,
          },
          expectReturnValue: [
            {
              toEqual: [1, 2, 3, 4],
            },
          ],
        },
        {
          description: "Push an item to an empty array",
          arguments: {
            array: [],
            value: 1,
          },
          expectReturnValue: [
            {
              toEqual: [1],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.IsEmpty",
    description: "Checks if an array is empty.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The array to check.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "return args.array.length === 0;\n",
      ruby: "args[:array].empty?\n",
    },
    returnValue: {
      schema: {
        type: "Boolean",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Empty array returns true",
          arguments: {
            array: [],
          },
          expectReturnValue: [
            {
              toEqual: true,
            },
          ],
        },
        {
          description: "Non-empty array returns false",
          arguments: {
            array: [1, 2, 3],
          },
          expectReturnValue: [
            {
              toEqual: false,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.Length",
    description: "Returns the number of items in an array.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The array to get the length of.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      ruby: "args[:array].length\n",
    },
    returnValue: {
      schema: {
        type: "Integer",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Get length of a non-empty array",
          arguments: {
            array: [1, 2, 3, 4, 5],
          },
          expectReturnValue: [
            {
              toEqual: 5,
            },
          ],
        },
        {
          description: "Get length of an empty array",
          arguments: {
            array: [],
          },
          expectReturnValue: [
            {
              toEqual: 0,
            },
          ],
        },
        {
          description: "Get length of an array with one item",
          arguments: {
            array: ["single item"],
          },
          expectReturnValue: [
            {
              toEqual: 1,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.Map",
    description:
      "Applies a function to each element of an array and returns a new array with the results.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The input array to map over.",
      },
      {
        name: "itemAlias",
        schema: {
          type: "String",
          required: false,
        },
        description: "The alias to use for the item in the function.",
        default: "item",
      },
      {
        name: "indexAlias",
        schema: {
          type: "String",
          required: false,
        },
        description:
          "The alias to use for the iteration index in the function.",
        default: "index",
      },
      {
        name: "functionCall",
        schema: {
          type: "FunctionCall",
          required: true,
        },
        description:
          "The function call instance to apply to each element of the array.",
        deferUntilExecution: true,
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "const array = args.array;\nconst itemAlias = args.itemAlias || 'item';\nconst indexAlias = args.indexAlias || 'index';\nconst functionCall = args.functionCall;\n\nreturn await Promise.all(array.map(async (item, index) => {\n  const iterationContext = context.clone();\n  iterationContext.merge({ $map: { [itemAlias]: item, [indexAlias]: index } });\n  return await functions['FunctionCall.Execute'].execute({ functionCall: functionCall }, iterationContext);\n}));\n",
      ruby: "array = args[:array]\nitem_alias = args[:itemAlias] || 'item'\nindex_alias = args[:indexAlias] || 'index'\nfunction_call = args[:functionCall]\n\narray.map.with_index do |item, index|\n  iteration_context = context.clone\n  iteration_context.merge!({ :$map => { item_alias.to_sym => item, index_alias.to_sym => index } })\n\n  Pidgin.functions['FunctionCall.Execute'][:lambda].call({ functionCall: function_call }, iteration_context)\nend\n",
    },
    returnValue: {
      schema: {
        type: "Array",
      },
    },
    characteristics: {
      readsContext: true,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Map over an array of numbers and double each element",
          arguments: {
            array: [1, 2, 3, 4, 5],
            functionCall: {
              $call: "Math.Add",
              $deferUntilExecution: true,
              $arguments: {
                a: {
                  $call: "Context.Get",
                  $arguments: {
                    keypath: "$map/item",
                  },
                },
                b: 2,
              },
            },
          },
          expectReturnValue: [
            {
              toEqual: [3, 4, 5, 6, 7],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.Slice",
    description:
      "Returns a shallow copy of a portion of an array into a new array object.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The array to slice.",
      },
      {
        name: "start",
        schema: {
          type: "Integer",
          required: true,
        },
        description:
          "The beginning index of the specified portion of the array.",
      },
      {
        name: "end",
        schema: {
          type: "Integer",
          required: false,
        },
        description:
          "The end index of the specified portion of the array. Defaults to the array's length.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      ruby: "array = args[:array]\nstart = args[:start]\nend_index = args[:end] || array.length\n\narray[start...end_index]\n",
      js: "const array = args.array;\nconst start = args.start;\nconst end = args.end !== undefined ? args.end : array.length;\n\nreturn array.slice(start, end);\n",
    },
    returnValue: {
      schema: {
        type: "Array",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Slice an array from index 1 to 3",
          arguments: {
            array: [1, 2, 3, 4, 5],
            start: 1,
            end: 3,
          },
          expectReturnValue: [
            {
              toEqual: [2, 3],
            },
          ],
        },
        {
          description: "Slice an array from index 2 to the end",
          arguments: {
            array: [1, 2, 3, 4, 5],
            start: 2,
          },
          expectReturnValue: [
            {
              toEqual: [3, 4, 5],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Array.Sum",
    description: "Sums all the elements of an array.",
    parameters: [
      {
        name: "array",
        schema: {
          type: "Array",
          required: true,
        },
        description: "The array of numbers to sum.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      ruby: "{{array}}.sum\n",
      js: "return {{array}}.reduce((sum, num) => sum + num, 0);\n",
    },
    returnValue: {
      schema: {
        type: "Number",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Sum an array of numbers",
          arguments: {
            array: [1, 2, 3, 4, 5],
          },
          expectReturnValue: [
            {
              toEqual: 15,
            },
          ],
        },
        {
          description: "Sum an empty array",
          arguments: {
            array: [],
          },
          expectReturnValue: [
            {
              toEqual: 0,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Context.Get",
    description: "Retrieves a value from the context using the given keypath.",
    parameters: [
      {
        name: "keypath",
        schema: {
          type: "String",
          required: true,
        },
        description: "The path to the desired value in the context.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "const { keypath } = args;\nreturn context.get(keypath);\n",
      ruby: "keypath = args[:keypath]\nreturn context.get(keypath)\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    characteristics: {
      readsContext: true,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Get a value from a simple keypath",
          arguments: {
            keypath: "$data/value",
          },
          context: {
            $data: {
              value: "Test Value",
            },
          },
          expectReturnValue: [
            {
              toEqual: "Test Value",
            },
          ],
        },
        {
          description: "Get a value from a nested path",
          arguments: {
            keypath: "$user/profile/name",
          },
          context: {
            $user: {
              profile: {
                name: "John Doe",
              },
            },
          },
          expectReturnValue: [
            {
              toEqual: "John Doe",
            },
          ],
        },
        {
          description: "Throw an error for a non-existent path",
          arguments: {
            keypath: "missing/path",
          },
          context: {},
          expectExecution: [
            {
              toThrow:
                "Root key 'missing' does not exist in context for keypath 'missing/path'",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Context.Set",
    description: "Sets a value in the context using the given keypath.",
    parameters: [
      {
        name: "keypath",
        schema: {
          type: "String",
          required: true,
        },
        description: "The path where the value should be set in the context.",
      },
      {
        name: "value",
        schema: {
          type: "Any",
          required: true,
        },
        description: "The value to be set at the specified keypath.",
      },
    ],
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "const { keypath, value } = args;\nreturn context.set(keypath, value);\n",
      ruby: "keypath = args[:keypath]\nvalue = args[:value]\ncontext.set(keypath, value)\nvalue\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    characteristics: {
      readsContext: false,
      writesContext: true,
      isSynchronous: true,
      isIdempotent: false,
      isDeterministic: true,
      hasSideEffects: true,
    },
    tests: {
      cases: [
        {
          description: "Set a value with a simple keypath",
          arguments: {
            keypath: "$data/value",
            value: "New Test Value",
          },
          context: {
            $data: {},
          },
          expectReturnValue: [
            {
              toEqual: "New Test Value",
            },
          ],
          expectContext: {
            $data: {
              value: "New Test Value",
            },
          },
        },
        {
          description: "Set a value with a nested path",
          arguments: {
            keypath: "$user/profile/name",
            value: "Jane Doe",
          },
          context: {
            $user: {
              profile: {},
            },
          },
          expectReturnValue: [
            {
              toEqual: "Jane Doe",
            },
          ],
          expectContext: {
            $user: {
              profile: {
                name: "Jane Doe",
              },
            },
          },
        },
        {
          description: "Set a value creating intermediate objects",
          arguments: {
            keypath: "new/nested/path",
            value: 42,
          },
          context: {},
          expectReturnValue: [
            {
              toEqual: 42,
            },
          ],
          expectContext: {
            new: {
              nested: {
                path: 42,
              },
            },
          },
        },
      ],
    },
  },
  {
    name: "Flowgraph.Execute",
    description:
      "Executes a Pidgin flowgraph object, handling the execution of sequences, variables, constants, and function calls within it.",
    parameters: [
      {
        name: "flowgraph",
        schema: {
          type: "Object",
          required: true,
          properties: {
            sequence: {
              type: "Array",
              required: true,
              items: {
                type: "Object",
                properties: {
                  $call: {
                    type: "String",
                    required: false,
                  },
                  $arguments: {
                    type: "Object",
                    required: false,
                  },
                  $assignTo: {
                    type: "String",
                    required: false,
                  },
                  $if: {
                    type: "Object",
                    required: false,
                  },
                  $then: {
                    type: "Object",
                    required: false,
                  },
                  $else: {
                    type: "Object",
                    required: false,
                  },
                },
              },
            },
          },
        },
      },
    ],
    context: {
      readsKeys: "*",
      writesKeys: "*",
    },
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "const { flowgraph } = args;\nconst { sequence: sequence } = flowgraph;\n\nif (!Array.isArray(sequence)) {\n  throw new Error('Flowgraph must have a sequence array');\n}\n\nlet finalReturnValue = { $empty: true };\n\nfor (const step of sequence) {\n  if (step.$call) {\n    const result = await functions['FunctionCall.Execute'].execute({ \n      functionCall: step,\n    }, context);\n\n    context.appendItem('$results', {returnValue: result});\n    finalReturnValue = result;\n  } else if (step.$if) {\n    let condition;\n\n    if (typeof step.$if === 'boolean') {\n      condition = step.$if;\n    } else {\n      condition = await functions['FunctionCall.Execute'].execute({ functionCall: step.$if }, context);\n    }\n\n    if (condition) {\n      finalReturnValue = await functions['Flowgraph.Execute'].execute({ flowgraph: step.$then }, context);\n    } else {\n      if (step.$else) {\n        finalReturnValue = await functions['Flowgraph.Execute'].execute({ flowgraph: step.$else }, context);\n      }\n    }\n  } else {\n    throw new Error('Unsupported step in sequence');\n  }\n}\nreturn finalReturnValue;\n",
      ruby: "flowgraph = args[:flowgraph]\nsequence = flowgraph[:sequence]\n\nraise 'Flowgraph must have a sequence array' unless sequence.is_a?(Array)\n\nfinal_return_value = { '$empty' => true }\n\nsequence.each do |step|\n  if step[:$call]\n    result = Pidgin.functions['FunctionCall.Execute'][:lambda].call({\n      functionCall: step,\n    }, context)\n\n    context.append_item('$results', { returnValue: result })\n    final_return_value = result\n  elsif step[:$if]\n    if step[:$if].is_a?(TrueClass) || step[:$if].is_a?(FalseClass)\n      condition = step[:$if]\n    else\n      condition = Pidgin.functions['FunctionCall.Execute'][:lambda].call({\n        functionCall: step[:$if]\n      }, context)\n    end\n    if condition\n      final_return_value = Pidgin.functions['Flowgraph.Execute'][:lambda].call({\n        flowgraph: step[:$then]\n      }, context)\n    elsif step[:$else]\n      final_return_value = Pidgin.functions['Flowgraph.Execute'][:lambda].call({\n        flowgraph: step[:$else]\n      }, context)\n    end\n  else\n    raise 'Unsupported step in sequence'\n  end\nend\n\nfinal_return_value\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    characteristics: {
      isExecuteFlowgraph: true,
    },
    tests: {
      cases: [
        {
          description: "Execute a flowgraph with simple function calls",
          arguments: {
            flowgraph: {
              sequence: [
                {
                  $call: "String.Capitalize",
                  $arguments: {
                    string: "hello world",
                  },
                },
                {
                  $call: "Utility.Log",
                  $arguments: {
                    message: "Test message",
                  },
                },
                {
                  $call: "String.Concatenate",
                  $arguments: {
                    strings: ["what", "is", "up"],
                    delimiter: " ",
                  },
                },
              ],
            },
          },
          expectReturnValue: [
            {
              toEqual: "what is up",
            },
          ],
        },
        {
          description: "Execute a flowgraph with context",
          context: {
            $foo: {
              bar: "World",
            },
          },
          arguments: {
            flowgraph: {
              sequence: [
                {
                  $call: "String.Concatenate",
                  $arguments: {
                    strings: [
                      "Hello",
                      {
                        $call: "Context.Get",
                        $arguments: {
                          keypath: "$foo/bar",
                        },
                      },
                    ],
                    delimiter: ", ",
                  },
                },
              ],
            },
          },
          expectReturnValue: [
            {
              toEqual: "Hello, World",
            },
          ],
        },
        {
          description: "Execute flowgraph with $assignTo",
          context: {
            $state: {
              fibonacciList: [0, 1, 1, 2, 3, 5],
            },
          },
          arguments: {
            flowgraph: {
              sequence: [
                {
                  $call: "Array.Slice",
                  $arguments: {
                    array: {
                      $call: "Context.Get",
                      $arguments: {
                        keypath: "$state/fibonacciList",
                      },
                    },
                    start: -2,
                  },
                  $assignTo: "$flow/lastTwo",
                },
                {
                  $call: "Array.Sum",
                  $arguments: {
                    array: {
                      $call: "Context.Get",
                      $arguments: {
                        keypath: "$flow/lastTwo",
                      },
                    },
                  },
                  $assignTo: "$flow/nextFibNumber",
                },
                {
                  $call: "Array.AppendItem",
                  $arguments: {
                    array: {
                      $call: "Context.Get",
                      $arguments: {
                        keypath: "$state/fibonacciList",
                      },
                    },
                    value: {
                      $call: "Context.Get",
                      $arguments: {
                        keypath: "$flow/nextFibNumber",
                      },
                    },
                  },
                  $assignTo: "$flow/newFibonacciList",
                },
                {
                  $call: "Context.Get",
                  $arguments: {
                    keypath: "$flow/newFibonacciList",
                  },
                },
              ],
            },
          },
          expectReturnValue: [
            {
              toEqual: [0, 1, 1, 2, 3, 5, 8],
            },
          ],
          expectContext: [
            {
              toEqual: null,
              $flow: {
                lastTwo: [3, 5],
                nextFibNumber: 8,
                newFibonacciList: [0, 1, 1, 2, 3, 5, 8],
              },
              $state: {
                fibonacciList: [0, 1, 1, 2, 3, 5, 8],
              },
            },
          ],
        },
        {
          description: "Execute flowgraph with $if condition",
          arguments: {
            flowgraph: {
              sequence: [
                {
                  $if: true,
                  $then: {
                    sequence: [
                      {
                        $call: "String.Concatenate",
                        $arguments: {
                          strings: ["Condition", "is", "true"],
                          delimiter: " ",
                        },
                      },
                    ],
                  },
                  $else: {
                    sequence: [
                      {
                        $call: "String.Concatenate",
                        $arguments: {
                          strings: ["Condition", "is", "false"],
                          delimiter: " ",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          expectReturnValue: [
            {
              toEqual: "Condition is true",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Flowgraph.GetFlowVariable",
    description: "Retrieves a flow variable by its name.",
    parameters: [
      {
        name: "variableName",
        schema: {
          type: "String",
          required: true,
        },
      },
    ],
    context: {
      readsKeys: ["$flowVariables"],
    },
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Get value of flow variable",
        },
        {
          type: "BindingEditor",
          forArgument: "variableName",
        },
      ],
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    implementation: {
      js: "const { variableName } = args;\nreturn context.get(`$flowVariables/${variableName}`);\n",
      ruby: 'variable_name = args[:variableName]\ncontext.get("$flowVariables/#{variable_name}")\n',
    },
    characteristics: {
      isContextReader: true,
    },
    tests: {
      cases: [
        {
          description: "Get an existing flow variable",
          context: {
            $flowVariables: {
              testVar: "Flow variable value",
            },
          },
          arguments: {
            variableName: "testVar",
          },
          expectReturnValue: [
            {
              toEqual: "Flow variable value",
            },
          ],
        },
        {
          description: "Attempt to get a non-existent flow variable",
          context: {
            $flowVariables: {},
          },
          arguments: {
            variableName: "nonExistentVar",
          },
          expectExecution: [
            {
              toThrow:
                "Path '$flowVariables/nonExistentVar' does not exist in context",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Flowgraph.GetFunctionArgument",
    description:
      "When a flowgraph is executed as a function implementation, this function will return an argument passed to the flowgraph by its name.",
    parameters: [
      {
        name: "argumentName",
        schema: {
          type: "String",
          required: true,
        },
      },
    ],
    context: {
      readsKeys: ["$arguments"],
    },
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Get value of function argument",
        },
        {
          type: "BindingEditor",
          forArgument: "argumentName",
        },
      ],
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    implementation: {
      js: "const { argumentName } = args;\nreturn context.get(`$arguments/${argumentName}`);\n",
      ruby: 'argument_name = args[:argumentName]\ncontext.get("$arguments/#{argument_name}")\n',
    },
    characteristics: {
      isContextReader: true,
    },
    tests: {
      cases: [
        {
          description: "Get an existing argument",
          context: {
            $arguments: {
              testArg: "Hello, World!",
            },
          },
          arguments: {
            argumentName: "testArg",
          },
          expectReturnValue: [
            {
              toEqual: "Hello, World!",
            },
          ],
        },
        {
          description: "Attempt to get a non-existent argument",
          context: {
            $arguments: {},
          },
          arguments: {
            argumentName: "nonExistentArg",
          },
          expectExecution: [
            {
              toThrow:
                "Path '$arguments/nonExistentArg' does not exist in context",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Flowgraph.GetPreviousStepReturnValue",
    description:
      "Retrieves the return value of the previous step in the flowgraph.",
    parameters: [],
    context: {
      readsKeys: ["$results"],
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Get previous step return value",
        },
      ],
    },
    implementation: {
      js: "const results = context.get('$results');\nif (Array.isArray(results) && results.length > 0) {\n  return results[results.length - 1].returnValue;\n} else {\n  throw new Error('No previous return value found');\n}\n",
      ruby: "results = context.get('$results')\nif results.is_a?(Array) && !results.empty?\n  results.last[:returnValue]\nelse\n  raise 'No previous return value found'\nend\n",
    },
    characteristics: {
      isContextReader: true,
    },
    tests: {
      cases: [
        {
          description: "Get previous step return value",
          context: {
            $results: [
              {
                returnValue: "Step 1 result",
              },
              {
                returnValue: "Step 2 result",
              },
            ],
          },
          expectReturnValue: [
            {
              toEqual: "Step 2 result",
            },
          ],
        },
        {
          description:
            "Attempt to get previous step return value with no results",
          context: {
            $results: [],
          },
          expectExecution: [
            {
              toThrow: "No previous return value found",
            },
          ],
        },
      ],
    },
  },
  {
    name: "FunctionCall.Execute",
    description:
      "Executes a Pidgin function call object with $call and $arguments, handling recursive resolution of array and object arguments.",
    parameters: [
      {
        name: "functionCall",
        schema: {
          type: "Object",
          required: true,
          properties: {
            $call: {
              type: "String",
              required: true,
            },
            $arguments: {
              type: "Object",
              required: false,
            },
          },
        },
      },
    ],
    context: {
      readsKeys: "*",
      writesKeys: "*",
    },
    editorTemplate: {
      type: "Private",
    },
    implementation: {
      js: "const { $call: functionName, $arguments: functionArgs, $assignTo: assignTo } = args.functionCall;\n\nasync function resolveArguments(node) {\n  if (Array.isArray(node)) {\n    return await Promise.all(node.map(resolveArguments));\n  } else if (typeof node === 'object' && node !== null) {\n    if (node.hasOwnProperty(\"$call\")) {\n      if (node.$deferUntilExecution) {\n        return node;\n      }\n      return await functions['FunctionCall.Execute'].execute({ functionCall: node }, context);\n    }\n    const resolved = {};\n    for (const [key, value] of Object.entries(node)) {\n      resolved[key] = await resolveArguments(value);\n    }\n    return resolved;\n  } else {\n    return node;\n  }\n}\n\nif (typeof functions[functionName]?.execute !== 'function') {\n  throw new Error(`Function '${functionName}' not found`);\n}\n\nconst resolvedArgs = await resolveArguments(functionArgs);\n\nconst result = await functions[functionName].execute(resolvedArgs, context);\n\nif (assignTo) {\n  context.set(assignTo, result);\n}\n\nreturn result;\n",
      ruby: "function_call = args[:functionCall]\nfunction_name = function_call[:$call]\nfunction_args = function_call[:$arguments]\nassign_to = function_call[:$assignTo]\n\nresolve_arguments = ->(node) do\n  case node\n  when Array\n    node.map { |item| resolve_arguments.call(item) }\n  when Hash\n    if node.key?(:$call)\n      if node[:$deferUntilExecution]\n        node\n      else\n        Pidgin.functions['FunctionCall.Execute'][:lambda].call({ functionCall: node }, context)\n      end\n    else\n      node.transform_values { |value| resolve_arguments.call(value) }\n    end\n  else\n    node\n  end\nend\n\nunless Pidgin.functions[function_name]&.dig(:lambda)&.respond_to?(:call)\n  raise \"Function '#{function_name}' not found\"\nend\n\nresolved_args = resolve_arguments.call(function_args)\n\nresult = Pidgin.functions[function_name][:lambda].call(resolved_args, context)\n\nif assign_to\n  context.set(assign_to, result)\nend\n\nresult\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    characteristics: {
      isExecuteFunctionCall: true,
    },
    tests: {
      cases: [
        {
          description: "Execute a simple function",
          arguments: {
            functionCall: {
              $call: "String.Capitalize",
              $deferUntilExecution: true,
              $arguments: {
                string: "hello world",
              },
            },
          },
          expectReturnValue: [
            {
              toEqual: "Hello world",
            },
          ],
        },
        {
          description: "Execute a function with array argument",
          arguments: {
            functionCall: {
              $call: "String.Concatenate",
              $deferUntilExecution: true,
              $arguments: {
                strings: ["Hello", "World"],
                delimiter: " ",
              },
            },
          },
          expectReturnValue: [
            {
              toEqual: "Hello World",
            },
          ],
        },
        {
          description: "Throw error for non-existent function",
          arguments: {
            functionCall: {
              $call: "NonExistent.Function",
              $deferUntilExecution: true,
              $arguments: {},
            },
          },
          expectExecution: [
            {
              toThrow: "Function 'NonExistent.Function' not found",
            },
          ],
        },
        {
          description:
            "Handle recursive resolution of array and object arguments with $call",
          arguments: {
            functionCall: {
              $call: "String.Concatenate",
              $deferUntilExecution: true,
              $arguments: {
                strings: [
                  {
                    $call: "String.Capitalize",
                    $arguments: {
                      string: "hello",
                    },
                  },
                  {
                    $call: "String.Capitalize",
                    $arguments: {
                      string: "world",
                    },
                  },
                ],
                delimiter: " ",
              },
            },
          },
          expectReturnValue: [
            {
              toEqual: "Hello World",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Loop.Times",
    description: "Executes a sequence of steps a specified number of times.",
    parameters: [
      {
        name: "times",
        schema: {
          type: "Number",
          required: true,
        },
        description: "The number of times to execute the sequence.",
      },
      {
        name: "flowgraph",
        schema: {
          type: "Flowgraph",
          required: true,
        },
        description: "The flowgraph to execute in each iteration.",
        deferUntilExecution: true,
      },
      {
        name: "indexAlias",
        schema: {
          type: "String",
          required: false,
        },
        description:
          "The alias to use for the iteration index in the sequence.",
        default: "index",
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Execute the following steps",
        },
        {
          type: "BindingEditor",
          forArgument: "times",
        },
        {
          type: "Text",
          text: "times",
        },
        {
          type: "FlowgraphEditor",
          forArgument: "flowgraph",
        },
      ],
    },
    implementation: {
      js: "const times = args.times;\nconst flowgraph = args.flowgraph;\nconst indexAlias = args.indexAlias || 'index';\n\nlet result;\nfor (let i = 0; i < times; i++) {\n  const iterationContext = context.clone();\n  iterationContext.merge({ $loop: { [indexAlias]: i } });\n  \n  for (const step of flowgraph.sequence) {\n    result = await functions['FunctionCall.Execute'].execute({ functionCall: step }, iterationContext);\n  }\n}\nreturn result;\n",
      ruby: "times = args[:times]\nflowgraph = args[:flowgraph]\nindex_alias = args[:indexAlias] || 'index'\n\nresult = nil\ntimes.times do |i|\n  iteration_context = context.clone\n  iteration_context.merge!({ :$loop => { index_alias.to_sym => i } })\n  \n  flowgraph[:sequence].each do |step|\n    result = Pidgin.functions['FunctionCall.Execute'][:lambda].call({ functionCall: step }, iteration_context)\n  end\nend\nresult\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
      description:
        "The return value of the last function call in the last iteration.",
    },
    characteristics: {
      readsContext: true,
      writesContext: false,
      isSynchronous: true,
      isIdempotent: false,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description:
            "Execute a sequence 3 times, incrementing a number each time",
          arguments: {
            times: 3,
            flowgraph: {
              sequence: [
                {
                  $call: "Math.Add",
                  $arguments: {
                    a: {
                      $call: "Context.Get",
                      $arguments: {
                        keypath: "$loop/index",
                      },
                    },
                    b: 1,
                  },
                },
              ],
            },
          },
          expectReturnValue: [
            {
              toEqual: 3,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Utility.Echo",
    description:
      "Returns the input value without modification, useful for debugging or passing values through a flowgraph.",
    parameters: [
      {
        name: "value",
        schema: {
          type: "Any",
          required: true,
        },
        description: "The value to be echoed back.",
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Enter the value to echo:",
        },
        {
          type: "BindingEditor",
          forArgument: "value",
        },
      ],
    },
    implementation: {
      js: "return args.value;\n",
      ruby: "args[:value]\n",
    },
    returnValue: {
      schema: {
        type: "Any",
      },
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [
        {
          description: "Echo a string",
          arguments: {
            value: "Hello, world!",
          },
          expectReturnValue: [
            {
              toEqual: "Hello, world!",
            },
          ],
        },
        {
          description: "Echo a number",
          arguments: {
            value: 42,
          },
          expectReturnValue: [
            {
              toEqual: 42,
            },
          ],
        },
        {
          description: "Echo a boolean",
          arguments: {
            value: true,
          },
          expectReturnValue: [
            {
              toEqual: true,
            },
          ],
        },
        {
          description: "Echo an object",
          arguments: {
            value: {
              name: "John Doe",
              age: 30,
            },
          },
          expectReturnValue: [
            {
              toEqual: {
                name: "John Doe",
                age: 30,
              },
            },
          ],
        },
        {
          description: "Echo null",
          arguments: {
            value: null,
          },
          expectReturnValue: [
            {
              toEqual: null,
            },
          ],
        },
      ],
    },
  },
  {
    name: "Utility.Log",
    description: "Logs a message to the console or other logging system.",
    parameters: [
      {
        name: "message",
        schema: {
          type: "Any",
          required: true,
        },
        description: "The message to be logged. Can be of any type.",
      },
      {
        name: "level",
        schema: {
          type: "String",
          enum: ["info", "warn", "error", "debug"],
          default: "info",
          required: false,
        },
        description: "The log level. Defaults to 'info' if not specified.",
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Log the message",
        },
        {
          type: "BindingEditor",
          forArgument: "message",
        },
      ],
    },
    implementation: {
      js: "console.log(args.message);\nreturn args.message;\n",
      ruby: "puts(args[:message])\nargs[:message]\n",
    },
    returnValue: {
      schema: {
        type: "String",
      },
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: true,
    },
    tests: {
      cases: [
        {
          description: "Log a simple message",
          arguments: {
            message: "Hello, world!",
          },
          expectReturnValue: [
            {
              toEqual: "Hello, world!",
            },
          ],
          expectExecution: [
            {
              toLog: "Hello, world!",
            },
          ],
        },
        {
          description: "Log a message with a specific level",
          arguments: {
            message: "Warning: Low disk space",
            level: "warn",
          },
          expectReturnValue: [
            {
              toEqual: "Warning: Low disk space",
            },
          ],
          expectExecution: [
            {
              toLog: "Warning: Low disk space",
            },
          ],
        },
      ],
    },
  },
  {
    name: "Utility.LogMultiple",
    description:
      "Logs multiple messages to the console or other logging system.",
    parameters: [
      {
        name: "messages",
        schema: {
          type: "Array",
          items: {
            type: "Any",
          },
          required: true,
        },
        description:
          "An array of messages to be logged. Each message can be of any type.",
      },
      {
        name: "level",
        schema: {
          type: "String",
          enum: ["info", "warn", "error", "debug"],
          default: "info",
          required: false,
        },
        description:
          "The log level for all messages. Defaults to 'info' if not specified.",
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Log the following messages:",
        },
        {
          type: "BindingEditor",
          forArgument: "messages",
        },
      ],
    },
    implementation: {
      js: "args.messages.forEach(message => console.log(message));\nreturn args.messages;\n",
      ruby: "args[:messages].each { |message| puts(message) }\nargs[:messages]\n",
    },
    returnValue: {
      schema: {
        type: "Array",
        items: {
          type: "Any",
        },
      },
    },
    characteristics: {
      isSynchronous: true,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: true,
    },
    tests: {
      cases: [
        {
          description: "Log multiple simple messages",
          arguments: {
            messages: [
              "Hello, world!",
              "This is a test",
              "Logging multiple messages",
            ],
          },
          expectReturnValue: [
            {
              toEqual: [
                "Hello, world!",
                "This is a test",
                "Logging multiple messages",
              ],
            },
          ],
          expectExecution: [
            {
              toLog: "Hello, world!",
            },
            {
              toLog: "This is a test",
            },
            {
              toLog: "Logging multiple messages",
            },
          ],
        },
        {
          description: "Log multiple messages with a specific level",
          arguments: {
            messages: ["Warning: Low disk space", "Warning: High CPU usage"],
            level: "warn",
          },
          expectReturnValue: [
            {
              toEqual: ["Warning: Low disk space", "Warning: High CPU usage"],
            },
          ],
          expectExecution: [
            {
              toLog: "Warning: Low disk space",
            },
            {
              toLog: "Warning: High CPU usage",
            },
          ],
        },
        {
          description: "Log an array with different types of messages",
          arguments: {
            messages: [
              "String message",
              42,
              true,
              {
                user: "John Doe",
                age: 30,
              },
            ],
          },
          expectReturnValue: [
            {
              toEqual: [
                "String message",
                42,
                true,
                {
                  user: "John Doe",
                  age: 30,
                },
              ],
            },
          ],
          expectExecution: [
            {
              toLog: "String message",
            },
            {
              toLog: 42,
            },
            {
              toLog: true,
            },
            {
              toLog: {
                user: "John Doe",
                age: 30,
              },
            },
          ],
        },
      ],
    },
  },
  {
    name: "Utility.Wait",
    description:
      "Waits for a specified number of seconds before continuing execution",
    parameters: [
      {
        name: "seconds",
        schema: {
          type: "Number",
          required: true,
        },
        description: "The number of seconds to wait",
      },
    ],
    editorTemplate: {
      type: "Form",
      elements: [
        {
          type: "Text",
          text: "Wait for",
        },
        {
          type: "BindingEditor",
          forArgument: "seconds",
        },
        {
          type: "Text",
          text: "seconds",
        },
      ],
    },
    implementation: {
      js: "return new Promise(resolve => setTimeout(resolve, args.seconds * 1000))\n",
    },
    returnValue: {
      schema: {
        type: null,
      },
    },
    characteristics: {
      isSynchronous: false,
      isIdempotent: true,
      isDeterministic: true,
      hasSideEffects: false,
    },
    tests: {
      cases: [],
    },
  },
];

export default functions;
