import React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { useContainerActions } from "./containerUtils";
import { ContainerStructure } from "./ViewgraphEditor";
import { StringValidation } from "zod";

interface EditableContainerStructureProps {
  keypath: string;
  onSelectContainer: (keypath: string | null) => void;
  selectedKeypath: string | null;
}

const EditableContainerStructure: React.FC<EditableContainerStructureProps> = ({
  keypath,
  onSelectContainer,
  selectedKeypath,
}) => {
  const { getFragment } = useAppDescriptorStore();
  const { handleContainerAction } = useContainerActions(keypath);
  console.log("keypath", keypath);
  const structure = getFragment(keypath) as ContainerStructure;

  const getSizeStyle = (
    parentDirection: "horizontal" | "vertical",
    size?: { value: number; unit: "px" | "fr" }
  ): React.CSSProperties => {
    if (!size) return {};
    const dimension = parentDirection === "horizontal" ? "width" : "height";
    return {
      [dimension]: size.unit === "fr" ? `${size.value}fr` : `${size.value}px`,
      flexGrow: size.unit === "fr" ? size.value : 0,
      flexShrink: size.unit === "fr" ? 1 : 0,
      flexBasis: size.unit === "fr" ? "0%" : "auto",
    };
  };

  const getBackgroundColor = (depth: number) => {
    const baseColor = 255;
    const shade = Math.max(baseColor - depth * 10, 240); // Decrease brightness slightly for each nesting level
    return `rgb(${shade}, ${shade}, ${shade})`;
  };

  const renderMoveButtons = (containerPath: StringValidation) => {
    console.log("containerPath", containerPath, selectedKeypath);
    const isVisible = containerPath === selectedKeypath;
    console.log("isVisible", isVisible);

    if (!isVisible) return null;

    const parentKeypath = containerPath.split("/").slice(0, -2).join("/");
    const parentContainer = getFragment(parentKeypath) as
      | ContainerStructure
      | undefined;
    console.log("parentContainer", parentContainer);

    if (!parentContainer || !parentContainer.subcontainers) return null;

    const containerId = containerPath.split("/").pop()?.split(":")[1];

    const containerIndex = parentContainer.subcontainers.findIndex(
      (c) => c.id === containerId
    );
    console.log("containerIndex", containerIndex);
    const direction = parentContainer.layoutDirection;
    const isFirstChild = containerIndex === 0;
    const isLastChild =
      containerIndex === parentContainer.subcontainers.length - 1;

    const buttonStyle: React.CSSProperties = {
      position: "absolute",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      border: "1px solid #ccc",
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 2000,
    };

    const positionStyle = {
      up: {
        top: "-12px",
        left: "50%",
        transform: "translateX(-50%)",
      } as React.CSSProperties,
      down: {
        bottom: "-12px",
        left: "50%",
        transform: "translateX(-50%)",
      } as React.CSSProperties,
      left: {
        left: "-12px",
        top: "50%",
        transform: "translateY(-50%)",
      } as React.CSSProperties,
      right: {
        right: "-12px",
        top: "50%",
        transform: "translateY(-50%)",
      } as React.CSSProperties,
    };

    const IconComponent = {
      up: ChevronUpIcon,
      down: ChevronDownIcon,
      left: ChevronLeftIcon,
      right: ChevronRightIcon,
    };

    return (
      <>
        {direction === "vertical" && !isFirstChild && (
          <div
            style={{ ...buttonStyle, ...positionStyle.up }}
            onClick={(e) => {
              e.stopPropagation();
              handleContainerAction("move", containerPath, { direction: "up" });
            }}
          >
            <IconComponent.up />
          </div>
        )}
        {direction === "vertical" && !isLastChild && (
          <div
            style={{ ...buttonStyle, ...positionStyle.down }}
            onClick={(e) => {
              e.stopPropagation();
              handleContainerAction("move", containerPath, {
                direction: "down",
              });
            }}
          >
            <IconComponent.down />
          </div>
        )}
        {direction === "horizontal" && !isFirstChild && (
          <div
            style={{ ...buttonStyle, ...positionStyle.left }}
            onClick={(e) => {
              e.stopPropagation();
              handleContainerAction("move", containerPath, {
                direction: "left",
              });
            }}
          >
            <IconComponent.left />
          </div>
        )}
        {direction === "horizontal" && !isLastChild && (
          <div
            style={{ ...buttonStyle, ...positionStyle.right }}
            onClick={(e) => {
              e.stopPropagation();
              handleContainerAction("move", containerPath, {
                direction: "right",
              });
            }}
          >
            <IconComponent.right />
          </div>
        )}
      </>
    );
  };

  const renderContainer = (
    container: ContainerStructure,
    parentDirection: "horizontal" | "vertical",
    containerPath: string
  ) => {
    const containerStyle: React.CSSProperties = {
      display: "flex",
      flexDirection:
        container.layoutDirection === "vertical" ? "column" : "row",
      position: "relative",
      boxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.1)", // Add a muted inset border
      backgroundColor: "white",
      ...(container.isRoot
        ? { width: "100%", height: "100%" }
        : getSizeStyle(parentDirection, container.size)),
    };

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      onSelectContainer(containerPath);
    };

    const sizeText = container.size
      ? `${container.size.value}${container.size.unit}`
      : "";

    return (
      <div
        key={container.id}
        style={containerStyle}
        onClick={handleClick}
        className={`relative ${
          containerPath === selectedKeypath ? "selected-container" : ""
        }`}
      >
        {(!container.subcontainers || container.subcontainers.length === 0) && (
          <div
            className="absolute inset-0 flex items-center justify-center pointer-events-none"
            style={{
              fontSize: "2rem",
              color: "rgba(0, 0, 0, 0.1)",
              fontWeight: "bold",
              zIndex: 1,
            }}
          >
            {sizeText}
          </div>
        )}

        {container.subcontainers && container.subcontainers.length > 0 ? (
          container.subcontainers.map((subcontainer: ContainerStructure) =>
            renderContainer(
              subcontainer,
              container.layoutDirection,
              `${containerPath}/subcontainers/id:${subcontainer.id}`
            )
          )
        ) : (
          <div className="w-full h-full"></div>
        )}
        {renderMoveButtons(containerPath)}
      </div>
    );
  };

  return (
    <div className="p-4">
      <div
        className="absolute inset-0 border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden"
        onClick={() => onSelectContainer(null)}
      >
        <style jsx>{`
          .selected-container::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid blue;
            pointer-events: none;
          }
        `}</style>
        {structure ? (
          renderContainer(structure, "vertical", keypath)
        ) : (
          <div className="text-red-500">Error: Invalid container structure</div>
        )}
      </div>
    </div>
  );
};

export default EditableContainerStructure;
